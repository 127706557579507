.sj-radio-group {
	padding: 0;
	margin: 0;

	li {
		display: flex;
		align-items: center;

		list-style-type: none;
		margin-bottom: 20px;
	}

	label {
		line-height: 20px;
		cursor: pointer;

		display: flex;
		align-items: center;
	}

	.check {
		$radioDiameter: 18px;

		flex: 0 0 auto;

		width: $radioDiameter;
		height: $radioDiameter;

		margin-right: 20px;

		background-color: var(--radiobox-background-color);

		border: 1px solid var(--radiobox-border-color);
		border-radius: 100%;

		position: relative;

		&:before{
			$radioDotOffset: ($radioDiameter - $radioDotDiameter) * 0.5;

			content:"";
			display: block;
			visibility: hidden;

			height: $radioDotDiameter;
			width: $radioDotDiameter;

			background-color: var(--radiobox-checked-color);
			border-radius: 100%;

			top: $radioDotOffset;
			left: $radioDotOffset;

			position: absolute;
		}
	}

	input {
		display: none;

		&:checked ~ .check:before {
			visibility: visible;
		}

		&:checked ~ .check {
			background-color: var(--radiobox-checked-background-color);
			border-color: var(--radiobox-checked-border-color);
		}
	}
}
