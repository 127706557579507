.login-wrapper,
.password-request,
.password-reset,
.two-fa-code-form,
.two-fa-code-recovery-form {

	.sj-button {
		width: 100%;
		margin-right: 0;
	}

	.password,
	.recover {
		float: right;
	}

	h1 {
		margin: 48px 0 42px;

		@include mobile {
			margin: 42px 0 25px;
		}

		@include mobile_small {
			margin: 39px 0 23px;
		}

	}

	.sj-message {
		margin-bottom: 20px;
		max-width: 100%;

		&:not(.negative):not(.positive):not(.info) {
			display: none;
		}
	}
}

.password-request,
.password-reset,
.two-fa-code-form,
.two-fa-code-recovery-form,
.two-fa-code-recovery-success {
	.password-request-hint,
	.password-reset-hint,
	.two-fa-login-hint,
	.two-fa-recovery-hint {
		margin-bottom: $base-font-size;
	}

	.back {
		@include link-styled;
		margin-top: 25px;
	}
}

.password-reset {
	.sj-form {
		.sj-form-group {
			&.recommendation,
			&.alias {
				margin-bottom: $form-group-bottom-margin * 0.5;
			}

			&.password-edit,
			&.password-strength {
				margin-bottom: var(--gap-s);
			}
		}
	}
}

.two-fa-code-recovery-success {
	text-align: center;
	margin-top: 60px;

	.icon {
		color: var(--icon-checked-color);
	}
}

.password-reset-wrapper {
	.recover {
		display: none;
	}
}

.hdf-login .sj-form {
	position: relative;

	.sj-form-group {
		display: flex;
		flex-direction: column;
	}

	div.sj-form-group {
		margin-bottom: 0;
	}

	a.password {
		position: absolute;
		right: 0;
	}
}
