password-strength {
	width: 100%;

	.indicator {
		height: 3px;
		width: 24%;
	}

	.indicators {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		margin-bottom: 5px;

		&.strength-none {
			.indicator {
				background-color: var(--password-strength-none-background-color);
			}
		}

		&.invalid,
		&.strength-0 {
			.indicator {
				background-color: var(--password-strength-none-background-color);
			}
			.indicator:nth-child(1) {
				background-color: var(--password-strength-very-weak-background-color);
			}
		}

		&.strength-1 {
			.indicator {
				background-color: var(--password-strength-none-background-color);
			}
			.indicator:nth-child(1),
			.indicator:nth-child(2) {
				background-color: var(--password-strength-weak-background-color);
			}
		}

		&.strength-2 {
			.indicator {
				background-color: var(--password-strength-none-background-color);
			}
			.indicator:nth-child(1),
			.indicator:nth-child(2),
			.indicator:nth-child(3) {
				background-color: var(--password-strength-medium-background-color);
			}
		}

		&.strength-3 {
			.indicator {
				background-color: var(--password-strength-strong-background-color);
			}
		}
	}
}
