$buttonBorderRadius: 8px;
$buttonBorderWidth: 1px;

$checkboxBorderRadius: 2px;
$editorBorderRadius: 8px;

$formBorderRadius: 12px;
$messageLinkDecoration: none;
$pdfviewToolbarInsetBorder: 1px;
$radioDotDiameter: 10px;

:root {
	--attention1: #fef6d1;
	--attention2: #fdb81e;
	--attention3: #ffc700;
	--background-hero: #fffaed;
	--brand: #ff8800;
	--error: #e20007;
	--error1: #ffe2e3;
	--error2: #ff4b51;
	--information1: #54c1ff;
	--primary: #0077bb;
	--primary1: #00308f;
	--primary2: #000f66;
	--primary3: #CCE4F1;
	--secondary: #555555;
	--secondary1: #f9f9f9;
	--secondary-150: #ebebeb;
	--secondary2: #dddddd;
	--secondary-250: #cccccc;
	--secondary3: #bbbbbb;
	--secondary-350: #a2a2a2;
	--secondary4: #888888;
	--secondary-450: #6e6e6e;
	--secondary-550: #3b3b3b;
	--secondary-600: #212121;
	--success: #2e8540;
	--success1: #b8f2b3;
	--success2: #68ea5d;
	--white: #ffffff;
	--opacity100: 1;
	--opacity40: 0.4;
}

:root {
	--app-link-separator-color: var(--secondary3);
	--backup-files-background: var(--secondary-150);
	--backup-files-button-active-background-color: var(--secondary);
	--backup-files-button-active-border-color: var(--secondary);
	--backup-files-button-background-color: var(--secondary-450);
	--backup-files-button-border-color: var(--secondary-450);
	--backup-files-button-color: var(--white);
	--backup-files-button-hover-background-color: var(--secondary4);
	--backup-files-button-hover-border-color: var(--secondary4);
	--backup-files-toolbar-item-active-color: var(--secondary-450);
	--backup-files-toolbar-item-color: var(--secondary-350);
	--backup-files-toolbar-item-hover-color: var(--secondary-450);
	--body-alternative-text-color: var(--white);
	--body-background-color: var(--white);
	--body-text-color: var(--secondary);
	--body-text-color-light: var(--secondary4);
	--breadcrumb-anchor-color: var(--body-text-color);
	--breadcrumb-bg-color: var(--body-background-color);
	--breadcrumb-link-active-color: var(--link-active-color);
	--breadcrumb-link-color: var(--link-color);
	--breadcrumb-link-hover-color: var(--link-hover-color);
	--breadcrumb-separator-color: var(--secondary-350);
	--breadcrumb-text-color: var(--body-text-color);
	--button-alternative-active-background-color: rgba(255, 255, 255, .25);
	--button-alternative-active-border-color: transparent;
	--button-alternative-active-color: var(--white);
	--button-alternative-background-color: transparent;
	--button-alternative-border-color: var(--white);
	--button-alternative-color: var(--white);
	--button-alternative-disabled-background-color: transparent;
	--button-alternative-disabled-border-color: rgba(255, 255, 255, .25);
	--button-alternative-disabled-color: rgba(255, 255, 255, .25);
	--button-alternative-hover-background-color: var(--white);
	--button-alternative-hover-border-color: var(--white);
	--button-alternative-hover-color: var(--primary);
	--button-icon-only-selected-background-color: var(--secondary-450);
	--button-icon-only-selected-border-color: var(--button-secondary-border-color);
	--button-icon-only-selected-icon-color: var(--white);
	--button-primary-active-background-color: var(--primary2);
	--button-primary-active-border-color: var(--primary2);
	--button-primary-background-color: var(--primary);
	--button-primary-border-color: var(--primary);
	--button-primary-color: var(--white);
	--button-primary-disabled-background-color: var(--primary);
	--button-primary-disabled-border-color: var(--primary);
	--button-primary-disabled-color: var(--button-primary-color);
	--button-primary-disabled-opacity: var(--opacity40);
	--button-primary-hover-background-color: var(--primary1);
	--button-primary-hover-border-color: var(--primary1);
	--button-primary-hover-color: var(--white);
	--button-secondary-active-background-color: var(--secondary);
	--button-secondary-active-border-color: var(--secondary);
	--button-secondary-active-color: var(--white);
	--button-secondary-background-color: var(--white);
	--button-secondary-border-color: var(--secondary4);
	--button-secondary-color: var(--secondary-450);
	--button-secondary-disabled-background-color: transparent;
	--button-secondary-disabled-border-color: var(--secondary4);
	--button-secondary-disabled-color: var(--secondary);
	--button-secondary-hover-background-color: var(--secondary4);
	--button-secondary-hover-border-color: var(--secondary4);
	--button-secondary-hover-color: var(--white);
	--checkbox-background-color: var(--white);
	--checkbox-border-color: var(--secondary-250);
	--checkbox-checked-background-color: var(--white);
	--checkbox-checked-border-color: var(--secondary-250);
	--checkbox-checked-color: var(--success);
	--checkbox-disabled-background-color: var(--secondary-150);
	--checkbox-disabled-icon-color: var(--secondary-250);
	--checkbox-disabled-text-color: var(--secondary-350);
	--checkbox-error-color: var(--error);
	--delete-icon-color: var(--secondary-350);
	--dropdown-arrow-color: var(--secondary-250);
	--dropdown-background-color: var(--white);
	--dropdown-border-color: var(--secondary-250);
	--dropdown-disabled-arrow-color: var(--secondary-350);
	--dropdown-disabled-background-color: var(--secondary-150);
	--dropdown-disabled-border-color: var(--secondary-250);
	--dropdown-disabled-text-color: var(--secondary-350);
	--dropdown-focused-arrow-color: var(--body-text-color);
	--dropdown-focused-border-color: var(--secondary4);
	--dropdown-hover-arrow-color: var(--secondary4);
	--dropdown-hover-border-color: var(--secondary4);
	--dropdown-text-color: var(--body-text-color);
	--drop-up-down-flyout-active-color: var(--brand);
	--drop-up-down-flyout-hover-color: var(--brand);
	--drop-up-down-flyout-icon-open-color: var(--brand);
	--drop-up-down-flyout-normal-color: var(--secondary-450);
	--editor-focused-icon-color: var(--button-secondary-color);
	--editor-icon-color: var(--button-secondary-color);
	--editor-placeholder-color: var(--secondary-350);
	--editor-selection-background-color: var(--primary);
	--editor-selection-color: var(--white);
	--editor-text-color: var(--body-text-color);
	--editor-validation-busy-icon-color: var(--secondary-350);
	--editor-validation-error-icon-color: var(--error);
	--edit-panel-background-color: var(--secondary1);
	--edit-section-edit-background: var(--secondary-150);
	--empty-item-color: var(--secondary-350);
	--feedback-rating-active-color: var(--brand);
	--feedback-rating-color: var(--secondary3);
	--feedback-textarea-border-color: var(--secondary-250);
	--feedback-text-color: var(--body-text-color);
	--feedback-text-placeholder-color: var(--secondary-350);
	--flyout-background-color: var(--menu-background-color);
	--flyout-border-color: var(--menu-border-color);
	--flyout-link-active-background-color: var(--menu-item-active-background-color);
	--flyout-link-active-color: var(--menu-item-active-color);
	--flyout-link-color: var(--menu-item-color);
	--flyout-link-hover-background-color: var(--menu-item-hover-background-color);
	--flyout-link-hover-color: var(--menu-item-hover-color);
	--flyout-link-selected-background-color: var(--secondary-150);
	--flyout-link-selected-border-color: var(--brand);
	--flyout-link-selected-color: var(--body-text-color);
	--flyout-ruler-color: var(--flyout-border-color);
	--flyout-shadow: var(--menu-shadow);
	--fm-drag-shadow-symbol-background-color: var(--white);
	--fm-drag-shadow-symbol-border: 1px solid var(--secondary-350);
	--fm-drag-shadow-text-color: var(--secondary-350);
	--fm-drop-bg: var(--secondary1);
	--fm-drop-border-color: var(--secondary3);
	--fm-empty-invitation-border-color: var(--secondary3);
	--fm-empty-invitation-font-color: var(--secondary-250);
	--fm-empty-item-font-color: var(--empty-item-color);
	--fm-header-bg-color: var(--body-background-color);
	--fm-item-grid-bg: transparent;
	--fm-item-grid-color: var(--body-text-color);
	--fm-item-grid-highlight-bg: #cce4f1;
	--fm-item-grid-hover-bg: var(--secondary-150);
	--fm-item-grid-hover-color: var(--primary);
	--fm-item-grid-hover-icon-color: var(--secondary3);
	--fm-item-grid-icon-color: var(--secondary-250);
	--fm-item-grid-icon-color-thumbnail: var(--white);
	--fm-item-grid-icon-hover-color: var(--primary);
	--fm-item-grid-icon-selected-color: var(--secondary-350);
	--fm-item-grid-select-bg: rgba(255, 255, 255, .2);
	--fm-item-grid-select-color: rgba(0, 0, 0, .15);
	--fm-item-grid-selected-bg: var(--secondary2);
	--fm-item-grid-selected-select-bg: var(--brand);
	--fm-item-grid-selected-select-color: var(--white);
	--fm-item-grid-selecticon-bg: rgba(0, 0, 0, .3);
	--fm-item-grid-thumbnail-bg: rgba(0, 0, 0, .1);
	--fm-item-grid-thumbnail-label-bg: var(--secondary-600);
	--fm-item-grid-thumbnail-label-color: var(--white);
	--fm-item-list-bg: var(--list-bg-color);
	--fm-item-list-border: var(--list-item-border);
	--fm-item-list-color: var(--body-text-color);
	--fm-item-list-disabled-color: var(--list-header-color);
	--fm-item-list-header-color: var(--list-header-color);
	--fm-item-list-hover-bg: var(--secondary-150);
	--fm-item-list-hover-color: var(--primary);
	--fm-item-list-hover-icon-color: var(--secondary3);
	--fm-item-list-icon-color: var(--secondary-250);
	--fm-item-list-icon-hover-color: var(--primary);
	--fm-item-list-icon-selected-color: var(--secondary-350);
	--fm-item-list-select-color: var(--secondary-600);
	--fm-item-list-selected-bg: var(--secondary2);
	--fm-item-list-select-hover-color: var(--brand);
	--fm-item-list-thumbnail-bg: var(--secondary-150);
	--fm-item-list-thumbnail-hover-bg: var(--secondary2);
	--fm-item-preview-icon-background-color: rgba(255, 255, 255, .7);
	--fm-sort-flyout-sort-indicator-hover-color: rgba(255, 255, 255, .5);
	--fm-sort-flyout-sort-indicator-next-hover-color: var(--white);
	--fm-sort-flyout-sort-indicator-selected-current-color: var(--brand);
	--fm-sort-flyout-sort-indicator-selected-next-color: var(--secondary-350);
	--fm-toolbar-item-active-color: var(--primary);
	--fm-toolbar-item-color: var(--secondary-450);
	--fm-toolbar-item-hover-color: var(--primary1);
	--fm-toolbar-item-selected-color: var(--link-selected-color);
	--fm-toolbar-separator-color: var(--secondary-450);
	--fm-view-mode-selected-color: var(--link-icon-selected-color);
	--fm-view-mode-tile-border-color: var(--secondary-450);
	--fm-view-mode-tile-color: var(--secondary-450);
	--form-background-color: var(--secondary-150);
	--form-input-not-set-color: var(--secondary-250);
	--form-label-disabled-color: var(--secondary-350);
	--form-message-error-color: var(--error);
	--form-message-success-color: var(--success1);
	--fv-button-background-color: var(--secondary-550);
	--fv-button-border-color: var(--secondary4);
	--fv-button-text-color: var(--secondary1);
	--fv-button-hover-background-color: var(--secondary);
	--fv-button-hover-border-color: var(--secondary);
	--fv-button-hover-text-color: var(--secondary1);
	--fv-button-active-background-color: var(--secondary-450);
	--fv-button-active-border-color: var(--secondary-450);
	--fv-button-active-text-color: var(--secondary1);
	--fv-detail-background-color: var(--secondary-600);
	--fv-detail-header-color: var(--body-alternative-text-color);
	--fv-detail-sub-header-color: var(--secondary-350);
	--fv-detail-text-color: var(--body-alternative-text-color);
	--fv-link-active-color: var(--link-alternative-active-color);
	--fv-link-color: var(--link-alternative-color);
	--fv-link-hover-color: var(--link-alternative-hover-color);
	--fv-loading-icon-color: var(--loading-icon-color);
	--fv-overlay-background: var(--secondary-550);
	--fv-overlay-close-active-color: var(--fv-link-active-color);
	--fv-overlay-close-color: var(--fv-link-color);
	--fv-overlay-close-hover-color: var(--fv-link-hover-color);
	--fv-overlay-text-color: var(--secondary-350);
	--fv-status-background-color: var(--secondary-600);
	--fv-status-progress-bar-background-color: var(--secondary-350);
	--fv-status-progress-bar-handle-border: var(--secondary-250);
	--fv-status-progress-bar-handle-color: var(--secondary-250);
	--fv-status-progress-bar-progress-color: var(--primary);
	--fv-status-progress-bar-text-color: var(--secondary-350);
	--h1-color: var(--body-text-color);
	--h2-color: var(--body-text-color);
	--h3-color: var(--body-text-color);
	--h4-color: var(--body-text-color);
	--hdshare-footer-background-color: var(--sfm-footer-background-color);
	--hdshare-footer-link-active-color: var(--sfm-footer-link-active-color);
	--hdshare-footer-link-active-underline-color: var(--sfm-footer-link-active-underline-color);
	--hdshare-footer-link-color: var(--sfm-footer-link-color);
	--hdshare-footer-link-hover-color: var(--sfm-footer-link-hover-color);
	--hdshare-footer-link-hover-underline-color: var(--sfm-footer-link-hover-underline-color);
	--hdshare-footer-separator-border-left-color: var(--sfm-footer-separator-border-left-color);
	--hdshare-header-background-color: var(--brand);
	--hdshare-header-link-active-color: rgba(255, 255, 255, .85);
	--hdshare-header-link-color: var(--white);
	--hdshare-header-link-hover-color: rgba(255, 255, 255, .7);
	--hdshare-loading-icon-color: var(--loading-icon-color);
	--icon-checked-color: var(--success);
	--icon-default-color: var(--secondary-450);
	--icon-highlight-color: var(--brand);
	--icon-muted-color: var(--secondary2);
	--icon-radio-color: var(--secondary-350);
	--icon-radio-selected-color: var(--primary);
	--icon-search-clear-input-color: var(--secondary-450);
	--icon-unchecked-color: var(--secondary-250);
	--info-tooltip-icon-active-color: var(--primary1);
	--info-tooltip-icon-color: var(--primary);
	--info-tooltip-icon-hover-color: var(--primary1);
	--level-up-color: var(--primary);
	--level-up-disabled-color: var(--body-text-color);
	--link-active-color: var(--primary);
	--link-alternative-active-color: var(--secondary-150);
	--link-alternative-color: var(--secondary-350);
	--link-alternative-hover-color: var(--secondary2);
	--link-color: var(--primary);
	--link-hover-color: var(--primary2);
	--link-icon-active-color: var(--link-active-color);
	--link-icon-color: var(--link-color);
	--link-icon-hover-color: var(--link-hover-color);
	--link-icon-selected-color: var(--primary);
	--link-selected-color: var(--primary);
	--list-bg-color: var(--body-background-color);
	--list-header-color: var(--secondary-350);
	--list-header-hover-color: var(--primary);
	--list-item-border: 1px dotted var(--secondary3);
	--list-item-expired-background-color: var(--secondary-150);
	--list-item-icon-color: var(--body-text-color);
	--list-item-icon-color-stronger: var(--secondary-450);
	--list-item-invalid-background-color: var(--error1);
	--loading-icon-color: var(--secondary-250);
	--loading-icon-font-size: 50px;
	--menu-background-color: var(--white);
	--menu-border-color: var(--secondary-250);
	--menu-item-active-background-color: var(--brand);
	--menu-item-active-color: rgba(255, 255, 255, .7);
	--menu-item-color: var(--body-text-color);
	--menu-item-disabled-color: var(--secondary-250);
	--menu-item-hover-background-color: var(--brand);
	--menu-item-hover-color: var(--white);
	--menu-separator-color: var(--secondary-250);
	--menu-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
	--message-attention-border-color: var(--attention2);
	--message-background-color: var(--secondary1);
	--message-info-border-color: var(--primary);
	--message-negative-border-color: var(--error);
	--message-positive-border-color: var(--success);
	--message-text-color: var(--body-text-color);
	--mouse-selector-border-color: var(--secondary-450);
	--navigation-active-background-color: var(--secondary-150);
	--navigation-hover-background-color: var(--secondary-150);
	--navigation-icon-color: var(--secondary3);
	--navigation-menu-item-color: var(--menu-item-color);
	--navigation-menu-item-selected-color: var(--brand);
	--navigation-separator-color: var(--secondary3);
	--oauth2-logo-color: var(--brand);
	--office-overlay-content-background-color: var(--secondary2);
	--office-overlay-header-background-color: var(--secondary-550);
	--overlay-border-color: var(--white);
	--overlay-close-icon-active-color: var(--primary2);
	--overlay-close-icon-color: var(--body-text-color);
	--overlay-close-icon-hover-color: var(--primary);
	--overlay-close-icon-on-dark-background-active-color: var(--secondary4);
	--overlay-close-icon-on-dark-background-color: var(--white);
	--overlay-close-icon-on-dark-background-hover-color: var(--secondary-250);
	--overlay-close-icon-on-white-background-active-color: var(--primary2);
	--overlay-close-icon-on-white-background-color: var(--secondary-450);
	--overlay-close-icon-on-white-background-hover-color: var(--primary);
	--overlay-close-white-background-color: rgba(255, 255, 255, .5);
	--overlay-content-color: var(--white);
	--overlay-cover-color: rgba(0, 0, 0, .7);
	--overlay-error-background-color: var(--white);
	--overlay-error-icon-color: var(--error);
	--overlay-loading-cover-color: rgba(255, 255, 255, 0.5);
	--overlay-loading-icon-color: var(--loading-icon-color);
	--overlay-title-color: var(--body-text-color);
	--password-strength-medium-background-color: var(--attention2);
	--password-strength-none-background-color: var(--secondary-350);
	--password-strength-strong-background-color: var(--success);
	--password-strength-very-weak-background-color: var(--error);
	--password-strength-weak-background-color: var(--brand);
	--pdfview-toolbar-background-color: var(--white);
	--pdfview-toolbar-color: var(--secondary-600);
	--pdfview-toolbar-shadow-color: var(--secondary-250);
	--profile-ssh-key-list-separator: var(--secondary-600);
	--profile-ssh-key-upload-border: 2px dashed var(--secondary2);
	--protocol-path-overlay-list-background-color: var(--secondary-150);
	--protocol-path-overlay-list-border: 1px solid var(--secondary-250);
	--quota-available-background-color: transparent;
	--quota-bar-background-color: var(--secondary-150);
	--quota-segment-separator-color: var(--white);
	--quota-used-by-device-backups-background-color: var(--attention1);
	--quota-used-by-files-background-color: var(--brand);
	--quota-used-by-snapshots-background-color: var(--attention2);
	--radiobox-background-color: var(--white);
	--radiobox-border-color: var(--secondary-250);
	--radiobox-checked-background-color: var(--white);
	--radiobox-checked-border-color: var(--secondary-250);
	--radiobox-checked-color: var(--success);
	--sales-link-active-background-color: rgba(255, 255, 255, 0.75);
	--sales-link-active-border-color: rgba(255, 255, 255, 0.75);
	--sales-link-active-color: var(--primary);
	--sales-link-border-color: var(--white);
	--sales-link-color: var(--white);
	--sales-link-hover-background-color: var(--white);
	--sales-link-hover-border-color: var(--white);
	--sales-link-hover-color: var(--primary);
	--scroll-top-button-icon: url("client/icons/Up_Scroll_Button_light.svg");
	--search-menu-border-color: var(--secondary-450);
	--search-menu-button-active-border-color: var(--brand);
	--search-menu-button-background-color: transparent;
	--search-menu-button-background-hover-color: var(--secondary4);
	--search-menu-button-border-color: var(--secondary-450);
	--search-menu-button-border-left-color: var(--secondary4);
	--search-menu-button-color: var(--secondary-450);
	--search-menu-button-selected-background-color: var(--secondary);
	--search-menu-caret-down-color: var(--secondary-450);
	--search-menu-caret-down-selected-color: var(--white);
	--search-menu-checkbox-active-color: var(--white);
	--search-menu-checkbox-background-color: var(--brand);
	--search-menu-checkbox-checked-color: var(--white);
	--search-menu-checkbox-color: var(--secondary2);
	--search-menu-checkbox-hover-color: var(--brand);
	--search-menu-clear-button-background-color: var(--secondary);
	--search-menu-clear-button-border-color: var(--secondary-450);
	--search-menu-clear-button-color: var(--white);
	--search-menu-clear-button-hover-background-color: var(--secondary4);
	--search-menu-label-active-background-color: var(--brand);
	--search-menu-label-active-color: var(--white);
	--search-menu-label-color: var(--secondary-450);
	--search-menu-label-hover-background-color: var(--background-hero);
	--search-menu-label-hover-color: var(--secondary-450);
	--search-menu-label-selected-background-color: var(--secondary1);
	--search-menu-selected-button-color: var(--white);
	--setup-step-active-color: var(--brand);
	--setup-step-in-active-color: var(--secondary-250);
	--setup-success-icon-color: var(--success);
	--sfm-filemanager-background: var(--body-background-color);
	--sfm-footer-background-color: var(--secondary-150);
	--sfm-footer-extra-feedback-active-background-color: var(--primary1);
	--sfm-footer-extra-feedback-background-color: var(--secondary-450);
	--sfm-footer-extra-feedback-color: var(--white);
	--sfm-footer-extra-feedback-hover-background-color: var(--primary);
	--sfm-footer-link-active-color: var(--secondary-450);
	--sfm-footer-link-active-underline-color: var(--brand);
	--sfm-footer-link-color: var(--secondary-450);
	--sfm-footer-link-hover-color: var(--body-text-color);
	--sfm-footer-link-hover-underline-color: var(--brand);
	--sfm-footer-separator-border-left-color: var(--secondary-250);
	--sfm-header-color: var(--brand);
	--sfm-header-link-active-color: rgba(255, 255, 255, .85);
	--sfm-header-link-color: var(--white);
	--sfm-header-link-hover-color: rgba(255, 255, 255, .7);
	--sfm-header-text-color: var(--white);
	--sfm-main-section-separator-line-style: 1px dotted var(--secondary3);
	--sfm-protocols-header-text-color: var(--secondary-350);
	--sfm-target-dialog-share-icon-color: var(--secondary2);
	--sfm-target-dialog-share-icon-hover-color: var(--secondary-250);
	--sfm-upload-detail-overlay-data-current-amount-color: var(--secondary-600);
	--sfm-upload-detail-overlay-data-progress-amount-color: var(--secondary-450);
	--sfm-upload-detail-overlay-filename-color: var(--body-text-color);
	--sfm-upload-detail-overlay-item-icon-cancelled-color: var(--error);
	--sfm-upload-detail-overlay-item-icon-complete-color: var(--success1);
	--sfm-upload-detail-overlay-list-background: var(--white);
	--sfm-upload-detail-overlay-list-border-color: var(--secondary3);
	--sfm-upload-detail-overlay-progress-complete-background: var(--secondary-150);
	--sfm-upload-detail-overlay-progress-empty-background: var(--white);
	--sfm-upload-detail-overlay-progress-failed-background: var(--error1);
	--sfm-upload-detail-overlay-progress-running-background: #cce4f1;
	--sfm-upload-status-action-icon-color: var(--secondary);
	--sfm-upload-status-background: var(--secondary1);
	--sfm-upload-status-complete-color: var(--success);
	--sfm-upload-status-error-color: var(--error);
	--sfm-upload-status-icon-color: var(--white);
	--sfm-upload-status-loading-color: var(--primary);
	--sfm-upload-status-progress-bar-color: var(--secondary-250);
	--sfm-upload-status-progress-bar-loading-color: var(--primary);
	--sfm-upload-text-color: var(--secondary);
	--sharemanager-footer-delete-all-active-color: var(--link-active-color);
	--sharemanager-footer-delete-all-color: var(--link-color);
	--sharemanager-footer-delete-all-hover-color: var(--link-hover-color);
	--sharemanager-footer-delete-all-icon-active-color: var(--link-active-color);
	--sharemanager-footer-delete-all-icon-color: var(--link-color);
	--sharemanager-footer-delete-all-icon-hover-color: var(--link-hover-color);
	--share-overlay-expired-text-color: var(--secondary-350);
	--share-overlay-link-active-color: var(--link-active-color);
	--share-overlay-link-color: var(--link-color);
	--share-overlay-link-hover-color: var(--link-hover-color);
	--share-overlay-link-icon-active-color: var(--link-active-color);
	--share-overlay-link-icon-color: var(--link-color);
	--share-overlay-link-icon-hover-color: var(--link-hover-color);
	--share-overlay-link-separator-color: var(--fm-toolbar-separator-color);
	--share-upload-progress-background-color: var(--secondary1);
	--share-upload-progress-color: var(--primary);
	--slider-pager-color: var(--secondary-250);
	--slider-pager-hover-color: var(--primary);
	--slider-pager-selected-color: var(--primary);
	--slideshow-background-color: var(--overlay-close-white-background-color);
	--slideshow-button-active-color: var(--overlay-close-icon-on-white-background-active-color);
	--slideshow-button-color: var(--overlay-close-icon-on-white-background-color);
	--slideshow-button-hover-color: var(--overlay-close-icon-on-white-background-hover-color);
	--snapshot-list-item-hover-bg: var(--secondary-150);
	--status-item-color: var(--secondary-350);
	--tag-background-color: var(--secondary-150);
	--tag-editor-color: var(--body-text-color);
	--tag-focussed-background-color: #c6c6c6;
	--tag-focussed-remove-color: var(--secondary-350);
	--tag-focussed-remove-hover-color: var(--primary);
	--tag-hover-background-color: var(--secondary2);
	--tag-hover-text-color: var(--tag-text-color);
	--tag-remove-color: var(--secondary-350);
	--tag-remove-hover-color: var(--primary);
	--tag-text-color: var(--body-text-color);
	--text-editor-background-color: var(--white);
	--text-editor-border-color: var(--secondary-250);
	--text-editor-disabled-background-color: var(--secondary-150);
	--text-editor-disabled-color: var(--secondary-350);
	--text-editor-error-border-color: var(--error);
	--text-editor-error-focused-shadow: 0 0 8px rgba(204, 0, 0, 0.6);
	--text-editor-focused-border-color: var(--information1);
	--text-editor-focused-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
	--text-editor-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	--thumbnail-loader-icon-color: var(--loading-icon-color);
	--toggle-checked-color: var(--white);
	--toggle-checked-span-after-background-color-1: var(--secondary-150);
	--toggle-checked-span-after-background-color-2: var(--white);
	--toggle-checked-span-after-border-color: var(--secondary2);
	--toggle-checked-span-before-background-color: var(--success);
	--toggle-checked-span-before-border-color: var(--success);
	--toggle-disabled-span-after-background-color: var(--secondary-150);
	--toggle-disabled-span-after-border-color: var(--secondary2);
	--toggle-disabled-span-before-background-color: var(--secondary-150);
	--toggle-disabled-span-before-color: var(--secondary-250);
	--toggle-shadow-color: rgba(0, 0, 0, .15);
	--toggle-span-after-background-color-1: var(--secondary-150);
	--toggle-span-after-background-color-2: var(--white);
	--toggle-span-after-border-color: var(--secondary2);
	--toggle-span-before-background-color: var(--white);
	--toggle-span-before-border-color: var(--secondary2);
	--toggle-span-before-color: var(--secondary-550);
	--tooltip-background-color: var(--white);
	--tooltip-border-color: var(--secondary2);
	--tooltip-shadow-color: rgba(0, 0, 0, .1);
	--tooltip-tip-after-color: var(--white);
	--tooltip-tip-before-color: var(--secondary2);
	--two-fa-code-background: var(--white);
	--two-fa-hint-color: var(--secondary-350);
	--upsell-dialog-background-color: var(--white);
	--upsell-dialog-button-linked-active-color: var(--primary2);
	--upsell-dialog-button-linked-color: var(--primary);
	--upsell-dialog-button-linked-hover-color: var(--primary2);
	--upsell-dialog-button-primary-active-background-color: var(--primary1);
	--upsell-dialog-button-primary-active-border-color: var(--primary1);
	--upsell-dialog-button-primary-background-color: var(--primary);
	--upsell-dialog-button-primary-border-color: var(--primary);
	--upsell-dialog-button-primary-color: var(--white);
	--upsell-dialog-button-primary-hover-background-color: var(--primary1);
	--upsell-dialog-button-primary-hover-border-color: var(--primary1);
	--upsell-dialog-check-icon: var(--brand);
	--upsell-dialog-text-color: var(--secondary-550);
	--upsell-teaser-action-background-color: var(--white);
	--upsell-teaser-action-border-color: var(--secondary-250);
	--upsell-teaser-background-color: var(--secondary1);
	--upsell-topbar-background-color: var(--secondary-150);
	--upsell-topbar-hide-link-active-color: var(--link-active-color);
	--upsell-topbar-hide-link-color: var(--upsell-topbar-text-color);
	--upsell-topbar-hide-link-hover-color: var(--link-hover-color);
	--upsell-topbar-text-color: var(--secondary-550);
	--wait-overlay-loading-icon-color: var(--loading-icon-color);
	--warning-icon-color: var(--attention2);
}
