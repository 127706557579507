.textview {
	width: 100vh;
	max-width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 40px;
	box-sizing: border-box;

	.text-content {
		height: 100%;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 83.33%;

		textarea {
			resize: none;
			width: 100%;
			height: 100%;
			padding: 40px;
			border: 0;
			font-family: monospace;
			font-size: $base-font-size;
			box-sizing: border-box;

			&:focus {
				outline: 0;
			}
		}
	}
}
