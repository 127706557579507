.quota-view {
	.quota-text {
		font-weight: bold;
	}

	.less-than-to-show {
		display: none;
	}

	.sj-barchart {
		height: 20px;
		margin-top: var(--gap-m);
		background-color: var(--quota-bar-background-color);

		white-space: nowrap;
		overflow: hidden;

		border-radius: 2px;
	}

	.quota-wrapper {
		margin-top: var(--gap-m);
	}

	.sj-barchart-segment {
		border-right: 1px solid var(--quota-segment-separator-color);

		&.usedbyfiles {
			background-color: var(--quota-used-by-files-background-color);
		}
		&.usedbydevicebackups {
			background-color: var(--quota-used-by-device-backups-background-color);
		}
		&.usedbysnapshots {
			background-color: var(--quota-used-by-snapshots-background-color);
		}
		&.available {
			background-color: var(--quota-available-background-color);
		}

	}

	.quota-bar-explanation {

		margin-top: var(--gap-m);

		.segment {
			margin-bottom: var(--gap-xs);
			display: flex;
		}

		.mark {
			display: inline-block;
			flex: 0 0 auto;
			align-self: center;
			width: 12px;
			height: 12px;
			margin-right: 8px;

			border-radius: 2px;
		}
		.name {
			margin-right: 8px;
		}
		.value {
			font-weight: bold;
		}
		.usedbydevicebackups {
			.mark {
				background-color: var(--quota-used-by-device-backups-background-color);
			}
		}
		.usedbyfiles {
			.mark {
				background-color: var(--quota-used-by-files-background-color);
			}
		}
		.usedbysnapshots {
			.mark {
				background-color: var(--quota-used-by-snapshots-background-color);
			}
		}

	}
}
