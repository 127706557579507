.fm-folder-flyout {
	min-width: 0;

	.trigger {
		display: flex;

		white-space: nowrap;
		align-items: center;

		.label {
			flex: 1;

			font-weight: 600;

			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre;
		}

		&:before, &:after {
			left: 46px;
		}
	}

	.sep {
		border-left: 1px solid var(--body-text-color);
		width: 0px;
		margin: 0 10px;
		height: 20px;
	}

	.arrow {
		padding: 0 10px;
		cursor: pointer;
		position: relative;
		top: 2px;

		&.disabled {
			cursor: default;
		}
	}

	.icon-level-up {
		color: var(--level-up-color);
		cursor: pointer;

		&.disabled {
			color: var(--level-up-disabled-color);
			cursor: default;
		}
	}

	.folder-icon {
		height: 20px;
		width: 20px;
		> svg {
			color: var(--level-up-color);
		}
		cursor: pointer;

		&.disabled {
			> svg {
				color: var(--level-up-disabled-color);
			}
			cursor: default;
		}
	}

	.content {
		right: auto;
		left: 0;

		max-width: 200px;
		max-height: 400px;
		overflow-y: auto;

		li {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}
