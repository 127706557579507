.sj-checkbox {
	display: flex;
	align-items: center;

	line-height: 20px;
	cursor: pointer;

	display: flex;
	align-items: center;

	.check {
		flex: 0 0 auto;

		position: relative;
		margin-right: 20px;
		width: 18px;
		height: 18px;
		background-color: var(--checkbox-background-color);
		border: 1px solid var(--checkbox-border-color);
		border-radius: $checkboxBorderRadius;
		color: var(--checkbox-checked-color);
		display: block;
	}

	.check:before {
		visibility: hidden;
	}

	input {
		display: none;

		&:checked ~ .check:before {
			visibility: visible;
		}

		&:checked ~ .check {
			background-color: var(--checkbox-checked-background-color);
			border-color: var(--checkbox-checked-border-color);
		}
	}

	&.disabled {
		cursor: default;
		color: var(--checkbox-disabled-text-color);

		.check {
			background-color: var(--checkbox-disabled-background-color);
			color: var(--checkbox-disabled-icon-color);
		};
	}

	&.error {
		.check {
			border-color: var(--checkbox-error-color);
		}
	}
}
