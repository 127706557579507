upsell-teaser {
	display: grid;
	padding: 30px;
	margin-bottom: 40px;
	background-color: var(--upsell-teaser-background-color);
	border-radius: 8px;

	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"img head action"
		"img info action";

	grid-column-gap: 20px;
	align-items: center;

	@include portable_large {
		grid-template-rows: unset;
		grid-template-columns: 1fr auto;
		grid-template-areas:
			"head action"
			"img action"
			"info info";
	}

	@include mobile {
		grid-template-columns: auto;
		grid-row-gap: 35px;
		grid-template-areas:
			"head"
			"img"
			"info"
			"action";
	}

	@include mobile_small {
		padding: 16px;
		grid-row-gap: 28px;
	}

	.header {
		grid-area: head;
		align-self: end;

		@include mobile {
			align-self: start;
		}

		.caption {
			font-weight: 600;
			font-size: 24px;
			line-height: 26px;
			@include portable_large {
				font-size: 22px;
			}
		}
		.teaser {
			font-size: 22px;
			margin-top: 20px;
			@include portable_large {
				font-size: 20px;
			}
		}
	}

	.action {
		grid-area: action;
		text-align: center;
		justify-self: end;
		max-width: 402px;
		box-sizing: border-box;

		@include mobile {
			justify-self: center;
		}

		background-color: var(--upsell-teaser-action-background-color);
		border: 1px solid var(--upsell-teaser-action-border-color);
		border-radius: 8px;
		padding: 30px 26px 34px;
		@include mobile_small {
			padding: 16px 13px 24px;
		}

		li {
			list-style-type: none;
			font-size: 18px;
			line-height: 24px;
			display: grid;
			text-align: left;
			grid-template-columns: 30px 1fr;
			@include mobile_small {
				grid-template-columns: 25px 1fr;
			}

			&:before {
				@extend .fa-solid;
				content: '#{$fa-var-check}';
				color: var(--upsell-dialog-check-icon);
				align-self: center;
			}

			@include portable_large {
				font-size: 16px;
			}
		}

		button {
			margin: 34px 0 0;

			color: var(--upsell-dialog-button-primary-color);
			background-color: var(--upsell-dialog-button-primary-background-color);
			border-color: var(--upsell-dialog-button-primary-border-color);

			@include hover {
				background-color: var(--upsell-dialog-button-primary-hover-background-color);
				border-color: var(--upsell-dialog-button-primary-hover-border-color);
			}

			@include active {
				background-color: var(--upsell-dialog-button-primary-active-background-color);
				border-color: var(--upsell-dialog-button-primary-active-border-color);
			}
		}
	}

	.img {
		grid-area: img;

		width: 306px;
		margin: 40px 20px;

		@include portable_large {
			width: 279px;
			margin: 30px 20px 0;
		}

		@include portable {
			width: 250px;
			margin: 10px 20px 0;
		}

		@include mobile {
			width: 229px;
			justify-self: center;
		}

		@include mobile_small {
			margin: 20px;
		}
	}

	.info {
		grid-area: info;
		align-self: start;
		font-size: 18px;
		margin-top: 32px;
		@include portable {
			margin-top: 10px;
		}
		@include mobile {
			margin-top: 0;
		}
	}
}
