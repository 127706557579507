.audioview {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	.display-name {
		margin-top: 48px;
		margin-bottom: 48px;
		font-size: 20px;
		color: var(--fv-overlay-text-color);
	}

	.file-icon {
		width: 132px;
		height: 132px;
	}
}
