.filesviewer {
	display: flex;
	flex-direction: column;

	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	.filesviewer-wrapper {
		position: relative;

		display: flex;
		flex: 1;
		min-height: 0px;
	}

	.filesviewer-container {
		flex: 1;
		position: relative;
	}

	.filesviewer-content {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 67px;
	}
}
