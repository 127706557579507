.office-overlay {
	padding: 0;
	bottom: 0;

	.overlay-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		height: $fvHeaderHeight;
		padding: 0 $fvHeaderPaddingRight;

		.app-logo-wrapper {
			@include non-mobile(){
				flex: 1;
				display: flex;
			}
		}

		.display-name {
			flex: 1;
			color: var(--fv-overlay-text-color);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: center;
			padding-left: 30px;
			padding-right: 30px;
		}

		background-color: var(--office-overlay-header-background-color);
	}

	@include non-mobile(){
		.close-wrapper {
			flex: 1;
			text-align: right;
		}
	}

	@include mobile_small {
		.topbar-wrapper {
			display: none;
		}
	}

	.office-editor-close {
		cursor: pointer;

		color: var(--overlay-close-icon-on-dark-background-color);

		@include hover {
			color: var(--overlay-close-icon-on-dark-background-hover-color);
		}

		@include active {
			color: var(--overlay-close-icon-on-dark-background-active-color);
		}
	}

	.office-editor-content {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;

		width: 100%;

		background-color: var(--office-overlay-content-background-color);
	}

	.office-editor {
		flex: 1 0 100%;
		display: flex;
		flex-direction: column;

	}

	form {
		display: none;
	}

	iframe {
		flex: 1 0 100%;
		width: 100%;
		border: none;
	}
}
