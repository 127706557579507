.sj-toggle {
	display: inline-block;
	width: 70px;
	height: 28px;
	position: relative;
	cursor: pointer;

	input {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	span {
		width: 100%;
		height: 100%;
		display: inline-block;
		font-size: $base-font-size - 4px;

		/* OFF */
		&:before, &:after {
			display: block;
			border-radius: 28px;
			content: "";
		}

		&:before {
			position: relative;
			height: 28px;
			box-sizing: border-box;
			color: var(--toggle-span-before-color);
			width: 68px;
			content: "OFF";
			border: 1px solid var(--toggle-span-before-border-color);
			background: var(--toggle-span-before-background-color);
			line-height: 28px;
			padding-left: 31px;
		}

		&:after {
			position: absolute;
			height: 26px;
			width: 26px;
			border: 1px solid var(--toggle-span-after-border-color);
			left: 0;
			top: 0;
			background: linear-gradient(to top, var(--toggle-span-after-background-color-1), var(--toggle-span-after-background-color-2)) repeat-x scroll 0 0 var(--toggle-span-after-background-color-2);
			box-shadow: 1px 0 3px 0 var(--toggle-shadow-color);
		}
	}

	input:checked ~ span {
		/* ON */
		&:before {
			content: "ON";
			color: var(--toggle-checked-color);
			background: var(--toggle-checked-span-before-background-color);
			border: 1px solid var(--toggle-checked-span-before-border-color);
			padding-left: 10px;
			font-weight: 600;
		}

		&:after {
			left: 41px;
			box-shadow: -3px 0 3px 0 var(--toggle-shadow-color);
			background: linear-gradient(to top, var(--toggle-checked-span-after-background-color-1), var(--toggle-checked-span-after-background-color-2)) repeat-x scroll 0 0 var(--toggle-span-after-background-color-2);
			border-color: var(--toggle-checked-span-after-border-color);
		}
	}

	&.disabled {
		cursor: default;

		input {
			display: none;

			&:checked ~ span {
				opacity: .5;
			}
		}

		span {
			&:before {
				background: var(--toggle-disabled-span-before-background-color);
				color: var(--toggle-disabled-span-before-color);
			}

			&:after {
				background: var(--toggle-disabled-span-after-background-color);
				border-color: var(--toggle-disabled-span-after-border-color);
				box-shadow: none;
			}
		}
	}
}
