.statusview {
	position: fixed;
	bottom: 0;
	box-sizing: border-box;
	width: 100%;
	height: $fv_status_height;
	padding: 12px 20px 0;
	background-color: var(--fv-status-background-color);

	// evenly spread the with to all three columns using flexbox
	display: flex;
	flex-direction: row;

	.left, .right {
		// evenly spread the three columns
		flex: 1;
	}

	.center {
		flex: 2 1 0%;

		@include mobile_small {
			flex: 1;
		}
	}

	.left {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-top: 16px;

		@include mobile_small {
			display: none;
		}
	}

	.control-buttons {
		// pragmatic way to center buttons
		text-align: center;
		height: 30px;

		.icon {
			// any button
			padding: 0 18px;
			vertical-align: middle;

			&.start-diashow,
			&.stop-diashow,
			&.play,
			&.pause {
				border: 2px solid var(--fv-link-color);
				border-radius: 100%;
				margin: 0 18px;

				@include mobile_small() {
					margin: 0 6px;
				}
			}

			&.start-diashow,
			&.play {
				padding: 9px 10px 9px 15px;
			}
			&.stop-diashow,
			&.pause {
				padding: 9px 12px 9px 12px;
			}

			&[disabled] {
				display: none;
			}

			@extend %filesviewer-icon-button;

			@include mobile_small() {
				padding: 0 6px;
			}
		}
	}

	.toolbar-buttons {
		padding-top: 10px;
		white-space: nowrap;

		.icon {
			// any button
			display: none;
			line-height: inherit;
			padding: 4px;
			margin: 0 3px;

			@extend %filesviewer-icon-button;

			&.enabled {
				display: inline-block;
			}
		}

	}

	.right {
		text-align: right;

		@include mobile_small {
			text-align: center;
		}
	}

	.progress-bar {
		display: none;
		width: 100%;
		background-color: var(--fv-status-progress-bar-background-color);
		position: absolute;
		top: 0;
		left: 0;

		.has-duration & {
			display: block;
		}

		.bar {
			background-color: var(--fv-status-progress-bar-progress-color);
			height: 4px;
		}

		input[type="range"] {
			position: absolute;
			left: 0;
			top: -11px;
			right: 0;
			z-index: 10;
			-webkit-appearance: none;
			width: 100%;
			height: 25px;
			padding: 0;
			margin: 0;
			background: transparent;
		}

		input[type=range]::-webkit-slider-thumb {
			-webkit-appearance: none;
		}

		input[type=range]:focus {
			outline: none;
		}

		input[type=range]::-ms-track {
			width: 100%;
			cursor: pointer;
			background: transparent;
			border-color: transparent;
			color: transparent;
		}

		input[type=range]::-ms-fill-lower,
		input[type=range]:focus::-ms-fill-lower,
		input[type=range]::-ms-fill-upper,
		input[type=range]:focus::-ms-fill-upper {
			background: transparent;
		}

		input[type=range]::-moz-range-track {
			width: 100%;
			background-color: transparent;
		}

		input[type=range]::-moz-focus-outer {
			border: 0;
		}

		@mixin thumb() {
			height: 18px;
			width: 18px;
			background: var(--fv-status-progress-bar-handle-color);
			border-radius: 100%;
			cursor: pointer;
		}

		input[type=range]::-webkit-slider-thumb {
			-webkit-appearance: none;
			border: 1px solid var(--fv-status-progress-bar-handle-border);
			@include thumb();
		}

		input[type=range]::-moz-range-thumb {
			border: 1px solid var(--fv-status-progress-bar-handle-border);
			@include thumb();
		}

		input[type=range]::-ms-thumb {
			box-shadow: 0 0 0 1px var(--fv-status-progress-bar-handle-border);
			@include thumb();
		}

		input[type=range]::-ms-tooltip {
			display: none;
		}
	}

	.progress-time {
		display: none;
		margin-right: 15px;

		.has-duration & {
			@include non-mobile {
				display: inline-block;
			}
		}
	}

	.progress-current,
	.progress-total {
		font-size: 14px;
		color: var(--fv-status-progress-bar-text-color);
	}
}
