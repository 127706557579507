.sj-flyout {
	display: inline-block;
	font-size: 0; /*to eliminate extra-space added by browser for inline-block*/
	white-space: nowrap;

	&.open {
		// if another element is rendered "on top", the relative position will
		// result in a pseudo z-index, the flyout - or better the trigger view -
		// is visible. To prevent this behaviour, relative positioning is only
		// added if the flyout is open
		position: relative;
	}

	.trigger {
		&:before, &:after {
			display: none;
			content: "";
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 100%;
			margin-left: -#{$flyout-arrow-width*0.5};

			border-left: #{$flyout-arrow-width*0.5} solid transparent;
			border-bottom: $flyout-arrow-height solid var(--flyout-border-color);
			border-right: #{$flyout-arrow-width*0.5} solid transparent;
		}

		&:after {
			margin-top: 1px;
			border-bottom-color: var(--flyout-background-color);
		}

		.icon {
			vertical-align: bottom;
		}

		.label {
			font-size: 18px;
			line-height: 120%;
		}
	}

	.content {
		display: none;
		position: absolute;
		min-width: 200px;
		top: 100%;
		right: 0;
		margin-top: $flyout-arrow-height;
		padding: 10px 0;
		box-shadow: var(--flyout-shadow);
		list-style: none;
		background-color: var(--flyout-background-color);
		border: 1px solid var(--flyout-border-color);
		border-radius: $menu-border-radius;

		li {
			color: var(--flyout-link-color);
			padding: 0 10px;
			white-space: nowrap;
			line-height: 40px;
			font-size: $base-font-size;
			cursor: pointer;

			.label {
				display: inline-block;
				line-height: 22px;

				color: var(--flyout-link-color);
			}

			&.selected {
				background-color: var(--flyout-link-selected-background-color);

				.label {
					font-weight: 600;
					color: var(--flyout-link-selected-color);
					border-bottom: 3px solid var(--flyout-link-selected-border-color);
				}
			}

			&:not(.selected) {
				@include hover {
					background-color: var(--flyout-link-hover-background-color);

					.label {
						font-weight: 600;
						color: var(--flyout-link-hover-color);
					}
				}

				@include active {
					background-color: var(--flyout-link-active-background-color);

					.label {
						font-weight: 600;
						color: var(--flyout-link-active-color);
					}
				}
			}
		}

		hr {
			border: 0;
			height: 1px;
			background-color: var(--flyout-ruler-color);
			margin: 0;
		}
	}

	&.open {
		.trigger {
			&:before, &:after {
				display: inline-block;
			}
		}

		.content {
			display: block;
		}
	}
}
