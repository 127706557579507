
office-topbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 68px;
	padding: 0 30px;

	color: var(--upsell-topbar-text-color);
	background-color: var(--upsell-topbar-background-color);

	.close {
		display: flex;
		gap: 10px;
		align-items: center;
		cursor: pointer;

		.icon-topbar-hide {
			font-size: 20px;
		}

		color: var(--upsell-topbar-hide-link-color);
		font-weight: 600;

		@include hover {
			.icon, .text {
				color: var(--upsell-topbar-hide-link-hover-color);
			}

			.text {
				text-decoration: underline;
			}
		}

		@include active {
			.icon, .text {
				color: var(--upsell-topbar-hide-link-active-color);
			}

			.text {
				text-decoration: underline;
			}
		}
	}

	.message {
		flex: 1 0 0;
		margin: 0 20px;
		text-align: right;

		font-weight: 600;
	}

	.upgrade {
		margin-right: 0;

		.label {
			white-space: nowrap;
		}
	}
}

.office-dialog {
	.office-dialog-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 30px;
	}

	.overlay-title {
		width: auto;
		text-align: center;
		margin-bottom: 23px;
		font-size: 40px;
		line-height: 40px;
		color: var(--upsell-dialog-text-color);
		@include mobile_small {
			font-size: 28px;
			line-height: 28px;
		}
	}

	.sj-overlay-content {
		width: 756px;
		background-color: var(--upsell-dialog-background-color);
	}

	.sj-overlay-close {
		width: 60px;
		height: 60px;
	}

	.teaser {
		font-size: 22px;
		text-align: center;
		margin-top: 0;
		margin-bottom: 55px;
		color: var(--upsell-dialog-text-color);
	}

	ul {
		margin-top: 40px;
	}

	li {
		list-style-type: none;
		font-size: 16px;
		line-height: 24px;
		color: var(--upsell-dialog-text-color);
		display: grid;
		grid-template-columns: 30px 1fr;
		@include mobile_small {
			grid-template-columns: 25px 1fr;
		}
	}

	li:before {
		@extend .fa-solid;
		content: '#{$fa-var-check}';
		color: var(--upsell-dialog-check-icon);
		align-self: center;
	}

	.overlay-buttons {
		display: flex;
		flex-direction: column;

		button {
			align-self: center;
			margin-right: 0;
			font-size: 16px;
			height: 44px;
		}

		.order {
			color: var(--upsell-dialog-button-primary-color);
			background-color: var(--upsell-dialog-button-primary-background-color);
			border-color: var(--upsell-dialog-button-primary-border-color);

			@include hover {
				background-color: var(--upsell-dialog-button-primary-hover-background-color);
				border-color: var(--upsell-dialog-button-primary-hover-border-color);
			}

			@include active {
				background-color: var(--upsell-dialog-button-primary-active-background-color);
				border-color: var(--upsell-dialog-button-primary-active-border-color);
			}
		}

		.close {
			font-weight: 600;
			color: var(--upsell-dialog-button-linked-color);

			@include hover {
				color: var(--upsell-dialog-button-linked-hover-color);
			}

			@include active {
				color: var(--upsell-dialog-button-linked-active-color);
			}
		}
	}
}
