$file-item-fluid-border-radius: 4px;
$file-item-fluid-checkbox-padding: 4px;
$file-item-fluid-height: 140px;
$file-item-fluid-padding: 10px;
$file-item-fluid-selection-border-width: 2px;
$file-item-fluid-selection-border-radius: 2px;
$file-item-fluid-icon-size: 80px;
$file-item-fluid-icon-margin-top: calc(($file-item-fluid-height - $file-item-fluid-icon-size) / 2) - 16px;
$file-item-fluid-width: 140px;
$file-item-fluid-margin: 2px;

x-filemanager {
	fluid-content {
		height: 100%;
		// reserve space for the dropzone
		padding: calc($fm-dropzone-size - $fm-dropzone-border-size);
		// negate sj-item margin
		margin-left: -$file-item-fluid-margin;
		margin-top: -$file-item-fluid-margin;
	}

	fluid-item {
		.selecticon {
			display: none;
		}

		display: block;
		position: absolute;

		color: var(--fm-item-grid-color);
		overflow: hidden;
		margin: $file-item-fluid-margin;
		padding: 0;
		border-radius: $file-item-fluid-border-radius;
		// required to add a visual border without adjusting
		// width/height
		box-sizing: border-box;

		@include hover {
			.selecticon {
				display: block;
			}
		}

		&.notReadable {
			.file-item-icon {
				@include svg-icon-muted;
			}
		}
	}

	fluid-layout {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;

		.selecticon {
			position: absolute;
			top: $file-item-fluid-checkbox-padding + $file-item-fluid-selection-border-width;
			right: $file-item-fluid-checkbox-padding + $file-item-fluid-selection-border-width;
			z-index: 1;
			width: 24px;
			text-align: center;
			line-height: 24px;
			color: var(--fm-item-grid-select-color);
			border-radius: $checkboxBorderRadius;

			background-color: transparent;

			// hide inverted selecticon on default,
			// only required for listview:hover/-.selected
			div {
				display: none;
			}

			@include hover {
				background-color: var(--fm-item-grid-selected-select-bg);
				color: var(--fm-item-grid-selected-select-color);
				opacity: 1;
			}
		}
	}

	// selecticon for thumbnails
	fluid-item.isthumbnail {
		.selecticon {
			background-color: var(--fm-item-grid-select-bg);
			color: var(--fm-item-grid-selecticon-bg);

			@include hover {
				background: var(--fm-item-grid-selected-select-bg);
				color: var(--fm-item-grid-selected-select-color);
				opacity: 1;
			}
		}
	}

	// item content
	fluid-item {
		.itemcontent {
			// Fill grid/tile like item content to the corners
			position: absolute;
			padding: $file-item-fluid-padding;

			width: $file-item-fluid-width;
			height: $file-item-fluid-height;

			background: var(--fm-item-grid-bg);
		}

		&.isthumbnail .itemcontent {
			top: $file-item-fluid-padding;
			left: $file-item-fluid-padding;
			padding: 0;

			@include hover {
				.file-item-displayname {
					visibility: visible;
					transition: opacity .25s linear .5s;
					opacity: .7;
				}
			}
		}

		&.fluid-thumbnail .itemcontent {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
		}

		.file-item-icon {
			display: block;

			width: $file-item-fluid-icon-size;
			height: $file-item-fluid-icon-size;

			@include svg-icon-default;

			margin: $file-item-fluid-icon-margin-top auto 9px;

			&.file-item-image-preview {
				display: block;
				text-align: center;
				overflow: hidden;
				background-image: none;

				&.icon-mimetype-group-pdf {
					@include pdf-icon-thumb {
						font-weight: bolder;
						border-radius: 2px;
						padding: 1px 7px;
						top: 105px;
						width: 28px;
					}
				}

				&.icon-mimetype-group-video {
					@include video-icon-thumb {
						padding: 0 1px 0 3px;
						width: 26px;
						height: 30px;
						line-height: 30px;
						font-size: 16px;
						border-radius: 20px;
					}
				}
			}
		}

		.thumbnail {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
			background-position: center;
			background-repeat: no-repeat;
		}

		.file-item-label {
			position: static;
			padding: 0 10px;
			display: block;
			text-align: center;
			height: 40px;
			line-height: 20px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.display_name {
				white-space: pre;
			}
		}

		// Thumbnail not loaded for thumbnail-able file
		&:not(.isthumbnail) .group-thumb {
			.file-item-label {
				display: block;
			}
		}

		// icon element with the display_name column beside the label element
		.icon-item-shared {
			color: var(--fm-item-grid-icon-color);
			margin: 0 2px;
		}
	}

	// itemcontent for thumbnails
	fluid-item.isthumbnail {
		$display_name_inner_height: 26px;
		$display_name_vertical_padding: 12px;

		.file-item-displayname {
			box-sizing: border-box;
			height: $display_name_vertical_padding * 2 + $display_name_inner_height;
			color: var(--fm-item-grid-thumbnail-label-color);
			background: var(--fm-item-grid-thumbnail-label-bg);
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			padding: $display_name_vertical_padding 10px;
		}

		&:not(.fluid-thumbnail) {
			.file-item-displayname {
				bottom: -$file-item-fluid-padding;
				left: -$file-item-fluid-padding;
				right: -$file-item-fluid-padding;
			}
		}

		.icon-item-shared {
			color: var(--fm-item-grid-icon-color-thumbnail);
		}

		.thumbnail {
			background-repeat: no-repeat;
			background-position: 50%;

			// slightly darkened on hover
			@include hover {
				&.max_height,
				&.max_width,
				&.max_width_height {
					@include thumbnail_hover();
				}
			}
		}

		// cover thumbnails for the fluid images, not pdf etc
		&.fluid-thumbnail .thumbnail {
			background-size: cover;

			// slightly darkened on hover
			@include hover {
				&.max_small_height,
				&.max_small_width {
					@include thumbnail_hover();
				}
			}
		}
	}

	// selected items
	fluid-item.selected {
		border: $file-item-fluid-selection-border-width solid var(--fm-item-grid-selected-select-bg);
		background-color: var(--fm-item-grid-selected-bg);

		&:not(.fluid-thumbnail) .itemcontent {
			top: $file-item-fluid-padding - $file-item-fluid-selection-border-width;
			left: $file-item-fluid-padding - $file-item-fluid-selection-border-width;
			padding: 0;
		}

		&.fluid-thumbnail:not(.isthumbnail) .itemcontent {
			top: -$file-item-fluid-selection-border-width;
		}

		.thumbnail {
			// Compensate for fluid-item's border to maintain a constant
			// position for the background image.
			margin: -$file-item-fluid-selection-border-width;
		}

		.file-item-icon:not(.file-item-image-preview) {
			@include svg-icon-highlighted;
		}

		.selecticon {
			display: block;
			color: var(--fm-item-grid-selected-select-color);
			background-color: var(--fm-item-grid-selected-select-bg);
			opacity: 1;
			// adjust because of border for sj-item
			top: $file-item-fluid-checkbox-padding;
			right: $file-item-fluid-checkbox-padding;
		}

		&:not(.isthumbnail) .icon-item-shared {
			color: var(--fm-item-grid-icon-selected-color);
		}

		&.isthumbnail {
			.file-item-displayname {
				// adjust because of 3px border for sj-item
				padding-top: 12px;
				padding-bottom: 12px;
				width: auto;
				left: -$file-item-fluid-selection-border-width;
				right: -$file-item-fluid-selection-border-width;
				bottom: -$file-item-fluid-selection-border-width;
			}
		}
		&.isthumbnail:not(.fluid-thumbnail) {
			.file-item-displayname {
				padding-left: $file-item-fluid-padding - $file-item-fluid-selection-border-width;
				padding-right: $file-item-fluid-padding - $file-item-fluid-selection-border-width;
				left: -($file-item-fluid-padding - $file-item-fluid-selection-border-width);
				right: -($file-item-fluid-padding - $file-item-fluid-selection-border-width);
				bottom: -$file-item-fluid-padding;
			}
		}
	}

	// preselection
	fluid-item.preselected {
		background-color: var(--fm-item-grid-hover-bg);

		.itemcontent {
			.file-item-icon:not(.file-item-image-preview) {
				@include svg-icon-highlighted;
			}
		}

		.selecticon {
			display: block;
		}

		&.isthumbnail {
			.thumbnail {
				&.max_height,
				&.max_width,
				&.max_width_height {
					@include thumbnail_hover();
				}
			}
		}
	}

	fluid-item.sj-dragged-over {
		@extend %sj-dragged-over;

		.file-item-icon:not(.file-item-image-preview) {
			@include svg-icon-highlighted;
		}
	}

	// item content for readable items has hover
	&:not(.selected) {
		fluid-item:not(.notReadable):not(.predeselected)  {
			.itemcontent {
				@include hover {
					.file-item-icon:not(.file-item-image-preview) {
						@include svg-icon-highlighted;
					}

					.icon-item-shared {
						color: var(--fm-item-grid-hover-icon-color);
					}
				}

				.file-item-displayname .label-text {
					@include hover {
						color: var(--fm-item-grid-hover-color);
					}
				}

				.icon-item-shared {
					@include hover {
						color: var(--fm-item-grid-icon-hover-color);
					}
				}
			}
		}
	}

	fluid-item:not(.isthumbnail):not(.notReadable):not(.predeselected) {
		@include hover {
			background-color: var(--fm-item-grid-hover-bg);
		}
	}

	fluid-item.sj-grid-highlight {
		animation: sj-grid-highlight-fade 4s ease-in;

		.selecticon {
			animation: sj-grid-highlight-border-color-fade 4s ease-in;
		}
	}

	@keyframes sj-grid-highlight-fade {
		5% {
			background-color: var(--fm-item-grid-highlight-bg);
		}
		80% {
			background-color: var(--fm-item-grid-highlight-bg);
		}
		100% {
			background-color: var(--fm-item-grid-bg);
		}
	}

	@keyframes sj-grid-highlight-border-color-fade {
		5% {
			border-color: var(--fm-item-grid-highlight-bg);
		}
		80% {
			border-color: var(--fm-item-grid-highlight-bg);
		}
		100% {
			border-color: var(--fm-item-grid-bg);
		}
	}
}
