.imageview {
	&, .placeholder {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-repeat: no-repeat;
		background-position: 50%;
	}

	.placeholder {
		opacity: 0;

		&.transitioned {
			transition: opacity 0.5s;
		}

		&:not(.hidden) {
			opacity: 1;
		}
	}
}
