.fm-viewmode-flyout.open .content {
	display: flex;
	padding: 9px;

	min-width: auto;

	.tile {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		color: var(--fm-view-mode-tile-color);

		text-align: center;
		cursor: pointer;

		width: 80px;
		height: 80px;

		.icon {
			font-size: 28px;
			margin-top: var(--gap-m);
		}

		.viewmode-title {
			font-weight: 600;
			font-size: 14px;

			margin-bottom: var(--gap-m);
		}

		&:not(:last-child) {
			margin-right: 6px;
			border-right: 1px solid var(--fm-view-mode-tile-border-color);
		}

		&.selected {
			color: var(--fm-view-mode-selected-color);
			cursor: default;
		}
	}
}
