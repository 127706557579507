html, body {
	height: 100%;
}

body, input, textarea, button, select {
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: 125%;
}

body {
	color: var(--body-text-color);
	background-color: var(--body-background-color);
	margin: 0;
}

h1 {
	font: $h1-font;
	color: var(--h1-color);
	margin: $h1-margin;
	letter-spacing: -1px;
}

h2 {
	font: $h2-font;
	color: var(--h2-color);
	margin: 0;
	letter-spacing: -0.5px;
}

h3 {
	font: $h3-font;
	color: var(--h3-color);
}

h4 {
	font: $h4-font;
	color: var(--h4-color);
}

h5 {
	font: $h5-font;
	color: var(--body-text-color);
}

h1, h2, h3, h4, h5 {
	@extend .sj-selectable;
}

ul {
	padding: 0;
	margin: 0;
}

a {
	&:link, &:visited {
		@extend %link;
		outline: 0;
		text-decoration: none;
	}
}

flow-textlink {
	@extend %link;
}

%link {
	color: var(--link-color);
	cursor: pointer;

	@include hover {
		color: var(--link-hover-color);
		text-decoration: underline;
	}

	@include active {
		color: var(--link-active-color);
		text-decoration: underline;
	}
}

%icon-link {
	cursor: pointer;
	color: var(--link-icon-color);

	@include hover {
		color: var(--link-icon-hover-color);
	}

	@include active {
		color: var(--link-icon-active-color);
	}
}

.keep-linebreaks {
	white-space: pre-line;
}

.text-ellipsed {
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-small {
	font-size: $small-font-size;
	letter-spacing: 0.4px;
}

.text-light {
	color: var(--body-text-color-light);
}
