@mixin mobile_small(){
	@media only screen and (max-width: 479px) {
		@content;
	}
}

@mixin mobile(){
	@media only screen and (max-width: 749px) {
		@content;
	}
}

@mixin non-mobile(){
	@media only screen and (min-width: 750px) {
		@content;
	}
}

@mixin tablet(){
	@media only screen and (max-width: 1199px) and (min-width: 750px) {
		@content;
	}
}

@mixin tablet_large() {
	@media only screen and (max-width: 960px) {
		@content;
	}
}

@mixin portable(){
	@media only screen and (max-width: 1280px) {
		@content;
	}
}

@mixin portable_large(){
	@media only screen and (max-width: 1522px) {
		@content;
	}
}

@mixin desktop(){
	@media only screen and (min-width: 840px) {
		@content;
	}
}

@mixin non_desktop(){
	@media only screen and (max-width: 839px) {
		@content;
	}
}

@mixin hover(){
	@media only screen and (min-width: 992px) {
		&:hover{
			@content;
		}
	}
}

@mixin active(){
	@media only screen and (min-width: 992px) {
		&:active{
			@content;
		}
	}
}
