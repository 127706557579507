.fileview {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	.display-name {
		margin-top: 48px;
		font-size: 20px;
		color: var(--fv-overlay-text-color);
		max-width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		line-height: 120%;
	}

	.sj-button {
		margin-top: 48px;
		margin-right: 0;
		min-width: $button-min-width;
	}

	// keep label visible on small displays
	.sj-icon-button {
		.label {
			display: inline-block;
		}
	}

	.file-icon {
		width: 132px;
		height: 132px;
	}
}
