@import "./variables";

@import "client/theme";

// Partials
@import "./headerView";
@import "./footer";
@import "./errorpage";
@import "./forms";
@import "./login";
@import "./loading";
@import "./uploadDetails";
@import "./filemanager";
@import "./shareLinkView";

.hidden {
	display: none;
}

.overlayed {
	overflow: hidden;
}

body {
	// Use flexbox to allow correct height calculation when decendant elements
	// (like the footer) have a margin-bottom. Neccessary in Firefox ~65
	display: flex;
	flex-direction: column;

	> .appView {
		flex: 1 0 auto;

		display: flex;
		flex-direction: column;
	}
}

#content {

	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	margin: {
		top: calc($header-height + $header-bottom-space);
		right: $content-side-margin;
		bottom: $footer-top-space;
		left: $content-side-margin;
	}
	z-index: 0;

	[data-page="filemanager"] & {
		margin-top: calc($header-height + $header-bottom-space + $menubar-height + $menubar-to-content-space);
	}

	svelte-wrapper {
		min-height: 100%;

		x-filemanager {
			height: 100%;
		}
	}
}

.filemanager-menubar-wrapper {
	display: none;
	position: fixed;
	left: $content-side-margin;
	right: $content-side-margin;
	top: $header-height;
	padding: $header-bottom-space $content-side-margin $menubar-to-content-space;
	height: $menubar-height;
	z-index: 3;
	background-color: var(--fm-header-bg-color);

	.menubar-row {
		display: flex;
		align-items: center;
	}

	.fm-folder-flyout {
		background-color: var(--fm-header-bg-color);
		flex: 1;
	}

	.menubar {
		display: flex;
	}

	.toolbar {
		display: none;
		justify-content: flex-end;
		margin-top: 30px;

		&.active {
			display: flex;
		}
	}

	.sj-menuitem-disabled {
		display: none;
	}

	// only show when filemanger page is active
	[data-page="filemanager"] & {
		display: block;
	}
}

.show_eu_cookie {
	#footer {
		margin-bottom: $eu_cookie_height;

		@include tablet() {
			margin-bottom: $eu_cookie_height_tablet;
		}
		@include mobile() {
			margin-bottom: $eu_cookie_height_mobile;
		}
		@include mobile_small() {
			margin-bottom: $eu_cookie_height_mobile_small;
		}
	}
}
