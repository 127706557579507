$eu_cookie_height: 27px;
$eu_cookie_height_tablet: 47px;
$eu_cookie_height_mobile: 67px;
$eu_cookie_height_mobile_small: 87px;

.eu-cookie {
	background: var(--eu-cookie-bg-color);
	border-top: 2px solid var(--eu-cookie-top-border-color);
	color: var(--eu-cookie-color);
	font-size: $base-font-size - 2px;
	text-align: center;
	height: $eu_cookie_height;
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 10;
	display: table;

	@include tablet() {
		height: $eu_cookie_height_tablet;
	}
	@include mobile() {
		height: $eu_cookie_height_mobile;
	}
	@include mobile_small() {
		height: $eu_cookie_height_mobile_small;
	}

	p {
		display: table-cell;
		vertical-align: middle;
		padding: 0 40px 0 5px;
	}

	a:link, a:visited {
		text-decoration: underline;
		color: var(--eu-cookie-link-color);

		@include hover() {
			color: var(--eu-cookie-link-hover-color);
		}

		@include active() {
			color: var(--eu-cookie-link-active-color);
		}
	}

	.close {
		position: absolute;
		right: 0;
		display: inline-block;
		color: var(--eu-cookie-close-bg-color);
		margin: 4px 6px 3px 20px;
		cursor: pointer;
	}
}
