.shlnk {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 auto;

	min-height: inherit;

	.password-page {
		flex-shrink: 0;
	}
}

.shlnk-actions {
	padding-top: 20px;

	.download-button {
		margin-right: 0;

		width: auto;

		@include iconButtonPosition();

		.label {
			display: inline-block;
		}
	}
}

.shlnk-file-info {
	padding-bottom: 20px;
	font-size: $sharelink-title-font-size;
}

.shlnk-share-info {
	padding: 20px 0 0;
	// add margin when using flexed items
	// this text element should stick at the bottom
	margin-top: auto;

	// separator between ttl and download texts
	.ttl ~ .downloads:before {
		content: " | ";
	}

	@include mobile {
		.downloads {
			display: block;

			&:before {
				display: none;
			}
		}
	}
}

.shlnk-nopreview {
	.icon-shlnk-mimetype {
		height: 132px;
		width: 132px;

		@include svg-icon-default;

		margin: 20px auto;
	}

	display: none;

	.nopreview & {
		display: block;
	}
}

.shlnk {
	.sj-thumbnail {
		margin: 0 auto;
		min-height: 140px;
		width: 100%;

		position: relative;

		&.clickable {
			cursor: pointer;
		}

		&.image-loaded {
			flex: 1 0 auto;
		}

		&.sj-thumbnail-loading:before {
			// center loading icon
			position: absolute;
			left: calc(50% - var(--loading-icon-font-size) * 0.5);
			top: calc(50% - var(--loading-icon-font-size) * 0.5);
		}
	}

	.videoview {
		position: relative;
		min-height: 150px;
		width: 100%;
		flex: 1 0 auto;
	}

	&.nopreview {
		.sj-thumbnail {
			display: none;
		}

		.videoview {
			display: none;
		}
	}
}

.imageview-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: var(--fv-overlay-background);

	.image-loaded {
		width: 100%;
		height: 100%;
		// center loading icon
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.filesviewer-error{
		color: var(--fv-overlay-text-color);
		font-size: 20px;
		margin-top: 48px;
		margin-right: 20px;
		margin-left: 20px;
		text-align: center;
	}

	.thumb-icon {
		@include svg-icon-default;
		width: 132px;
		height: 132px;
	}
}

.malware-scan-test {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;

	.image svg{
		width: 220px;
	}

	.text {
		max-width: 330px;
		margin: 15px auto;
		text-align: center;
	}

	.common-loader {
		position: relative;
	}
}
