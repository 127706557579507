@import "./fm_fileItemTiles";
@import "./fm_fileItemList";
@import "./fm_fileItemFluid";

%sj-dragged-over {
	background-color: var(--fm-drop-bg);

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border: $fm-dropzone-border-size dashed var(--fm-drop-border-color);
		z-index: 2; // to show border on selected selecticon
	}
}

x-filemanager {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;

	position: relative;
	box-sizing: border-box;

	.view-switcher {
		display: flex;
		height: 100%;
		flex-direction: column;
	}

	.selection-boundary {
		position: absolute;
		left: -2px;
		right: -2px;
		// Move up to align with list-header top border.
		// When drop indication is active both border shall be congruent.
		top: -2px;
		bottom: 0;
		z-index: 0;
	}

	&.isDraggedOver {
		.selection-boundary {
			background-color: var(--fm-drop-bg);
			border: $fm-dropzone-border-size dashed var(--fm-drop-border-color);
		}

		list-layout, tile-layout, fluid-layout, .error-or-empty-state:not(.isEmpty .error-or-empty-state) {
			background-color: var(--fm-drop-bg);
			outline: $fm-dropzone-border-size dashed var(--fm-drop-border-color);
		}
	}

	&.selecting {
		list-item, tile-item, fluid-item {
			cursor: default;
		}
	}

	list-item, tile-item, fluid-item {
		cursor: pointer;

		&.notReadable {
			cursor: default;
		}
	}

	@include error-or-empty-view {
		.icon-search-cancel-big {
			display: block;
			text-align: center;
			margin: 0 auto 20px;
			color: var(--fm-empty-item-font-color);
		}
	}

	@import "./fm_statesView";
}

x-filemanager drag-shadow {
	background: none;
	overflow: hidden;

	position: absolute;
	left: -5000px;

	width: 24px;
	height: 32px;

	background: var(--fm-drag-shadow-symbol-background-color);
	border: var(--fm-drag-shadow-symbol-border);

	.drag-shadow-counter {
		position: absolute;
		z-index: 1;

		width: 100%;
		text-align: center;

		left: 0;
		bottom: 0.5em;

		color: var(--fm-drag-shadow-text-color);

		font-weight: bold;
		font-size: 10px;
	}
}
