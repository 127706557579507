.teaser-layout {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	max-width: 300px;
	margin: 20px auto 0; /* center */

	.text {
		margin-top: 10px;
		text-align: center;
	}

	.icon-tuev {
		background-image: url('client/static/teaser-tuev.svg');
		background-size: contain;
		width: 104px;
		height: 104px;
	}
}
