.drop-invitation {
	align-items: center;

	display: none;
	width: 106px;
	height: 111px;
	padding: 10px 15px 15px;
	margin: 20px auto 0;
	text-align: center;
	font-weight: normal;
	color: var(--fm-empty-invitation-font-color);
	border: 2px dashed var(--fm-empty-invitation-border-color);
	cursor: default;
}

@include desktop {
	.canDropUpload .drop-invitation {
		// flex to center vertically
		display: flex;
	}
}
