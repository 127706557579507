.pdfview {
	margin: auto;
	
	height: 100%;
	min-height: 100%;

	object {
		width: 100%;
		height: 100%;
		display: block;
	}

	canvas {
		width: 100%;
	}

	.document-toolbar {
		height: 30px;
		background-color: var(--pdfview-toolbar-background-color);
		color: var(--pdfview-toolbar-color);
		box-shadow: var(--pdfview-toolbar-shadow-color) 0px $pdfviewToolbarInsetBorder inset;
		white-space: nowrap;
		overflow: hidden;

		.pages, .navigation {
			line-height: 30px;
		}

		.pages label, .navigation {
			margin: 0 20px;
		}

		.pages {
			float: left;

			input[type=number]{
				width: 30px;
				text-align: right;

				-moz-appearance: textfield;
				&::-webkit-inner-spin-button,&::-webkit-outer-spin-button {
					-webkit-appearance: none;
				}
			}
		}

		.navigation {
			float: right;

			.nav-btn {
				border: 0 none;
				background: none;
				outline: 0;
				margin: 0 5px;
			}
		}
	}
}
