$table-header-height: 54px;
$file-item-list-item-height: 60px;
$file-item-list-icon-size: 40px;
$file-item-list-icon-margin-top: calc(($file-item-list-item-height - $file-item-list-icon-size) / 2);

x-filemanager {
	&.isEmpty {
		list-content {
			padding-top: 0;
		}
	}

	list-layout {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
	}

	&.isEmpty list-header {
		display: none;
	}

	list-header {
		border-top: $fm-dropzone-border-size solid var(--fm-item-list-bg);
		border-right: $fm-dropzone-border-size solid transparent;
		border-left: $fm-dropzone-border-size solid transparent;
		display: block;
		position: fixed;
		z-index: 2;

		padding: 0 calc($fm-dropzone-size - $fm-dropzone-border-size);

		@include mobile() {
			display: none;
		}

		list-item {
			height: $table-header-height;
			cursor: default;
			padding-top: 0;

			.itemcontent {
				@include hover {
					background-color: var(--fm-item-list-bg);
				}
			}

			.file-item-icon {
				background: none;
			}

			.file-item-label {
				color: var(--fm-item-list-header-color);
				line-height: $table-header-height;

				&.sortable {
					&:hover {
						color: var(--list-header-hover-color);
					}

					cursor: pointer;
				}

				.icon-list-ascending,
				.icon-list-descending {
					margin-left: 10px;
					display: none;
					vertical-align: text-top;
				}

				&.sortable:hover {
					.icon-list-ascending {
						display: inline-block;
					}
				}

				&.sorted.asc {
					.icon-list-ascending {
						display: inline-block;
					}

					.icon-list-descending {
						display: none;
					}
				}

				&.sorted.desc {
					.icon-list-ascending {
						display: none;
					}

					.icon-list-descending {
						display: inline-block;
					}
				}
			}
		}
	}

	&.isDraggedOver list-layout {
		list-header {
			list-item.itemcontent {
				@include hover {
					background-color: transparent;
				}
			}
		}
	}

	list-item {
		display: block;
		box-sizing: border-box;
		color: var(--fm-item-list-color);
		position: relative;
		overflow: hidden;
		float: none;
		width: auto;
		height: $file-item-list-item-height;
		background-color: var(--fm-item-list-bg);
		border-bottom: var(--fm-item-list-border);
		margin: 0;
		padding: 0 0 0 50px;
		white-space: nowrap;

		&.notReadable {
			.file-item-icon {
				@include svg-icon-muted;
			}
		}
	}

	list-layout {
		.selecticon {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 24px;
			text-align: center;
			line-height: 24px;
			color: var(--fm-item-list-select-color);
			opacity: .15;
			margin-top: 18px;
			margin-bottom: 18px;
			margin-left: 15px;
			margin-right: 15px;

			border-radius: $checkboxBorderRadius;

			.icon-item-selected {
				/* Hide this element since it's not required in the list view */
				display: none;
			}

			@include hover {
				color: var(--fm-item-list-bg);
				background-color: var(--fm-item-list-select-hover-color);
				opacity: 1;
			}
		}

		.itemcontent {
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;

			display: flex;
			flex-direction: row;
		}
	}

	// item content for readable items has hover, only when not in select mode
	list-content {
		box-sizing: border-box;

		flex: 1 0 auto;

		padding-top: $table-header-height;

		@include mobile() {
			padding-top: 0;
		}

		list-item:not(.selected):not(.predeselected):not(.notReadable) {
			.itemcontent {
				@include hover {
				 	background: var(--fm-item-list-hover-bg);

					.file-item-icon:not(.file-item-image-preview) {
						@include svg-icon-highlighted;
					}
				}
			}
		}
	}

	&:not(.selected) list-content {
		list-item:not(.notReadable) {
			.itemcontent {
				.file-item-displayname, .file-item-path {
					@include hover {
						color: var(--fm-item-list-hover-color);
					}
				}

				.icon-item-shared {
					@include hover {
						color: var(--fm-item-list-icon-hover-color);
					}
				}
			}
		}
	}

	list-layout {
		// rows
		.file-item-icon {
			height: $file-item-list-icon-size;
			width: $file-item-list-icon-size;

			@include svg-icon-default;

			margin: $file-item-list-icon-margin-top 10px 0 59px;

			display: block;

			&.file-item-image-preview {
				display: block;
				overflow: hidden;

				position: relative;

				&.icon-mimetype-group-pdf {
					@include pdf-icon-thumb {
						font-weight: bold;
						border-radius: 2px;
						top: 18px;
						padding: 1px 3px;
						font-size: 10px;
						width: 18px;
					}
				}

				&.icon-mimetype-group-video {
					@include video-icon-thumb {
						padding: 0 1px 0 3px;
						width: 20px;
						height: 24px;
						line-height: 24px;
						font-size: 13px;
						border-radius: 15px;
					}
				}
			}
		}

		.thumbnail {
			width: $file-item-list-icon-size;
			height: $file-item-list-icon-size;
			margin-top: 0;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			background-color: var(--fm-item-list-thumbnail-bg);

			&.max_width, &.max_width_height {
				background-size: $file-item-list-icon-size auto;
			}

			&.max_height {
				background-size: auto $file-item-list-icon-size;
			}

			&.max_small_height {
				background-size: auto 34px;
			}

			&.max_small_width {
				background-size: 34px auto;
			}
		}

		.file-item-label {
			display: block;
			vertical-align: top;
			line-height: 60px;
			padding: 0 10px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.file-item-displayname {
			flex: 1;

			.display_name {
				white-space: pre;
			}
			.icon-item-shared {
				display: none;
			}
		}

		.file-item-share-icon {
			padding: 22px 5px;
			min-width: 12px;

			&.icon-item-shared {
				color: var(--fm-item-list-icon-color);
			}
		}

		.file-item-size {
			width: 100px;
		}

		.file-item-date {
			width: 126px;

			@include mobile_small(){
				display: none;
			}
		}

		.file-item-category {
			width: 100px;

			@include tablet_large(){
				display: none;
			}
		}

		.file-item-size, .file-item-category, .file-item-share-icon, .file-item-share-icon.icon-item-shared {

			@include mobile(){
				display: none;
			}
		}

		.file-item-path {
			width: 200px;

			/* Hide parent path/name column for header and body by default
			 * It will be displayed per-app.
			 */
			display: none;
		}
	}

	list-item.selected {
		background-color: var(--fm-item-list-selected-bg);

		.selecticon {
			color: var(--fm-item-list-bg);
			background-color: var(--fm-item-list-select-hover-color);
			border-color: var(--fm-item-list-selected-bg);
			opacity: 1;
			border-radius: $checkboxBorderRadius;
		}

		.icon-item-shared {
			color: var(--fm-item-list-icon-selected-color);
		}

		.file-item-icon:not(.file-item-image-preview) {
			@include svg-icon-highlighted;
		}
	}

	// preselection
	list-item.preselected {
		background-color: var(--fm-item-list-hover-bg);

		.itemcontent {
			.file-item-icon:not(.file-item-image-preview) {
				@include svg-icon-highlighted;
			}
		}
	}


	list-item.sj-dragged-over {
		@extend %sj-dragged-over;

		.file-item-icon:not(.file-item-image-preview) {
			@include svg-icon-highlighted;
		}
	}

	list-item .itemcontent {
		@include hover {
			.icon-item-shared {
				color: var(--fm-item-list-hover-icon-color);
			}
		}
	}

	&.isDraggedOver {
		list-layout list-header {
			border-top: $fm-dropzone-border-size dashed var(--fm-drop-border-color);
			border-right: $fm-dropzone-border-size solid transparent;
			border-left: $fm-dropzone-border-size solid transparent;
		}

		list-item {
			background-color: var(--fm-drop-bg);
			.selecticon {
				border-color: var(--fm-drop-bg);
			}
		}
	}

	list-layout .sj-grid-highlight {
		animation: sj-grid-highlight-fade 4s ease-in;

		.selecticon {
			animation: sj-grid-highlight-border-color-fade 4s ease-in;
		}
	}

	@keyframes sj-grid-highlight-fade {
		5% {
			background-color: var(--fm-item-grid-highlight-bg);
		}
		80% {
			background-color: var(--fm-item-grid-highlight-bg);
		}
		100% {
			background-color: var(--fm-item-grid-bg);
		}
	}

	@keyframes sj-grid-highlight-border-color-fade {
		5% {
			border-color: var(--fm-item-grid-highlight-bg);
		}
		80% {
			border-color: var(--fm-item-grid-highlight-bg);
		}
		100% {
			border-color: var(--fm-item-grid-bg);
		}
	}
}
