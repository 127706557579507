$page-dot-width: 10px;
$page-dot-height: 10px;
$page-dot-padding: 5px;

page-control {
	display: flex;
	justify-content: center;

	ul {
		display: flex;
		flex-direction: row;

		.page {
			display: inline-block;
			vertical-align: top;

			padding-left: $page-dot-padding;
			padding-right: $page-dot-padding;

			span {
				display: inline-block;
				width: $page-dot-width;
				height: $page-dot-height;

				cursor: pointer;
				background-color: var(--slider-pager-color);

				border-radius: 100%;

				&:hover {
					background-color: var(--slider-pager-hover-color);
				}
			}

			&.selected {
				span {
					background-color: var(--slider-pager-selected-color);
					cursor: default;
				}
			}
		}
	}
}

