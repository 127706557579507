/* Common styles for all applications to get rid of annoying browser default
 * behaviours.
 */

body {

	/* disable highlight in androids chrome browser */
	tap-highlight-color: unquote("rgba(0,0,0,0)");
	/* disable highlight in androids stock browser and safari on iphone */
	-webkit-tap-highlight-color: unquote("rgba(0,0,0,0)");

	/*Prevent iPhone Text Enlargement*/
	-webkit-text-size-adjust: none;

	/*Prevent iPhone long touch info*/
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-ms-overflow-style: scrollbar;

	/*Prevent textfield to use text cursor*/
	cursor: default;
}

@-ms-viewport { width: device-width; }
@viewport { width: device-width; }

.sj-selectable {
	-webkit-user-select: text;
	-khtml-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
	cursor: auto;
}

/* search input */
input[type=search] {
	-webkit-appearance: none;

	/* removes the clear 'X' from Chrome and Safari */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
}

fieldset {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border: none;

	padding: 0;
	margin: 0;
}

/* button-border (in active state) */
button::-moz-focus-inner {
	border: 0;
}

/* device detection */
.s-hide {
	@include mobile(){
		display: none;
	}
}

.m-hide {
	@include tablet(){
		display: none;
	}
}
