.videoview {
	video {
		overflow: auto;
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		max-width: 100%;
		max-height: 100%;
	}
}
