%loader {
	display: flex;
	align-items: center;
	justify-content: center;

	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.common-loader {
	@extend %loader;
	color: var(--loading-icon-color);
}
