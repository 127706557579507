$file-item-tile-size: 140px;
$file-item-tile-checkbox-padding: 4px;
$file-item-tile-icon-size: 80px;
$file-item-tile-icon-margin-top: calc(($file-item-tile-size - $file-item-tile-icon-size) / 2) - 16px;
$file-item-tile-selection-border-width: 2px;
$file-item-tile-selection-border-radius: 4px;

x-filemanager {
	tile-content {
		box-sizing: border-box;

		flex: 1 0 auto;
	}

	tile-item {
		.selecticon {
			display: none;
		}

		display: inline-block;
		vertical-align: middle;
		width: $file-item-tile-size;
		height: $file-item-tile-size;
		color: var(--fm-item-grid-color);
		position: relative;
		overflow: hidden;
		margin: 10px;
		padding: 0;
		border-radius: $file-item-tile-selection-border-radius;
		// required to add a visual border without adjusting
		// width/height
		box-sizing: border-box;

		@include hover {
			.selecticon {
				display: block;
			}
		}

		&.notReadable {
			.file-item-icon {
				@include svg-icon-muted;
			}
		}
	}

	tile-layout {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;

		.selecticon {
			position: absolute;
			top: $file-item-tile-checkbox-padding + $file-item-tile-selection-border-width;
			right: $file-item-tile-checkbox-padding + $file-item-tile-selection-border-width;
			z-index: 1;
			width: 24px;
			text-align: center;
			line-height: 24px;
			color: var(--fm-item-grid-select-color);
			border-radius: $checkboxBorderRadius;
			background-color: transparent;

			// hide inverted selecticon on default,
			// only required for listview:hover/-.selected
			div {
				display: none;
			}

			@include hover {
				background-color: var(--fm-item-grid-selected-select-bg);
				color: var(--fm-item-grid-selected-select-color);
				opacity: 1;
			}
		}
	}

	// selecticon for thumbnails
	tile-item.isthumbnail {
		.selecticon {
			background-color: var(--fm-item-grid-select-bg);
			color: var(--fm-item-grid-selecticon-bg);

			@include hover {
				background: var(--fm-item-grid-selected-select-bg);
				color: var(--fm-item-grid-selected-select-color);
				opacity: 1;
			}
		}
	}

	// item content
	tile-item {
		.itemcontent {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background: var(--fm-item-grid-bg);
		}

		.file-item-icon {
			height: $file-item-tile-icon-size;
			width: $file-item-tile-icon-size;

			@include svg-icon-default;

			display: block;

			margin: $file-item-tile-icon-margin-top auto 9px;

			&.file-item-image-preview {
				display: block;
				text-align: center;
				overflow:hidden;
				background-image: none;

				&.icon-mimetype-group-pdf {
					@include pdf-icon-thumb {
						font-weight: bolder;
						border-radius: 2px;
						padding: 1px 7px;
						top: 105px;
						width: 28px;
					}
				}

				&.icon-mimetype-group-video {
					@include video-icon-thumb {
						padding: 0 1px 0 3px;
						width: 26px;
						height: 30px;
						line-height: 30px;
						font-size: 16px;
						border-radius: 20px;
					}
				}
			}
		}

		.thumbnail {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
			background-position: center;
			background-repeat: no-repeat;
		}

		.file-item-label {
			display: block;
			text-align: center;
			height: 40px;
			line-height: 20px;
			padding: 0 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.display_name {
				white-space: pre;
			}
		}

		// icon element with the display_name column beside the label element
		.icon-item-shared {
			color: var(--fm-item-grid-icon-color);
			margin: 0 2px;
		}
	}

	// itemcontent for thumbnails
	tile-item.isthumbnail {
		.file-item-displayname {
			height: 26px;
			width: 120px;
			color: var(--fm-item-grid-thumbnail-label-color);
			background: var(--fm-item-grid-thumbnail-label-bg);
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: 90px;
			left: 0;
			padding: 12px 10px;
		}

		.icon-item-shared {
			color: var(--fm-item-grid-icon-color-thumbnail);
		}

		.itemcontent {
			// thumbnail itself
			.thumbnail {
				&.max_width, &.max_width_height {
					background-size: $file-item-tile-size auto;
				}

				&.max_height {
					background-size: auto $file-item-tile-size;
				}

				&.max_small_height {
					background-size: auto $file-item-tile-size - 20px;
				}

				&.max_small_width {
					background-size: $file-item-tile-size - 20px auto;
				}
			}

			@include hover {
				// thumbnail itself
				.file-item-icon .max_height,
				.file-item-icon .max_width,
				.file-item-icon .max_width_height {
					@include thumbnail_hover();
				}

				.file-item-displayname {
					visibility: visible;
					transition: opacity .25s linear .5s;
					opacity: .7;
				}
			}
		}
	}

	// selected items
	tile-item.selected {
		border: $file-item-tile-selection-border-width solid var(--fm-item-grid-selected-select-bg);

		.itemcontent {
			background-color: var(--fm-item-grid-selected-bg);
		}

		.file-item-icon {
			margin-top: $file-item-tile-icon-margin-top - $file-item-tile-selection-border-width;

			&:not(.file-item-image-preview) {
				@include svg-icon-highlighted;
			}

			&.icon-mimetype-group-pdf::after {
				top: 102px;
			}
		}

		.file-item-label {
			padding: 0 7px;
		}

		.selecticon {
			display: block;
			color: var(--fm-item-grid-selected-select-color);
			background-color: var(--fm-item-grid-selected-select-bg);
			opacity: 1;
			top: $file-item-tile-checkbox-padding;
			right: $file-item-tile-checkbox-padding;
		}

		&:not(.isthumbnail) .icon-item-shared {
			color: var(--fm-item-grid-icon-selected-color);
		}

		&.isthumbnail {
			.file-item-displayname {
				// adjust because of 3px border for sj-item
				top: 87px;
				padding: 12px 7px 9px;
			}
		}
	}

	// preselection
	tile-item.preselected {
		background-color: var(--fm-item-grid-hover-bg);

		.itemcontent {
			.file-item-icon:not(.file-item-image-preview) {
				@include svg-icon-highlighted;
			}
		}

		.selecticon {
			display: block;
		}

		&.isthumbnail {
			.itemcontent {
				.file-item-icon .max_height,
				.file-item-icon .max_width,
				.file-item-icon .max_width_height {
					@include thumbnail_hover();
				}
			}
		}
	}

	tile-item.sj-dragged-over {
		@extend %sj-dragged-over;

		.file-item-icon:not(.file-item-image-preview) {
			@include svg-icon-highlighted;
		}
	}

	// item content for readable items has hover
	&:not(.selected) {
		tile-item:not(.notReadable):not(.predeselected) {
			.itemcontent {
				@include hover {
					.file-item-icon:not(.file-item-image-preview) {
						@include svg-icon-highlighted;
					}

					.icon-item-shared {
						color: var(--fm-item-grid-hover-icon-color);
					}
				}

				.file-item-displayname .label-text {
					@include hover {
						color: var(--fm-item-grid-hover-color);
					}
				}

				.icon-item-shared {
					@include hover {
						color: var(--fm-item-grid-icon-hover-color);
					}
				}
			}
		}
	}

	tile-item:not(.isthumbnail):not(.notReadable):not(.predeselected) .itemcontent {
		@include hover {
			background-color: var(--fm-item-grid-hover-bg);
		}
	}

	tile-item.sj-grid-highlight {
		animation: sj-grid-highlight-fade 4s ease-in;

		.selecticon {
			animation: sj-grid-highlight-border-color-fade 4s ease-in;
		}
	}

	@keyframes sj-grid-highlight-fade {
		5% {
			background-color: var(--fm-item-grid-highlight-bg);
		}
		80% {
			background-color: var(--fm-item-grid-highlight-bg);
		}
		100% {
			background-color: var(--fm-item-grid-bg);
		}
	}

	@keyframes sj-grid-highlight-border-color-fade {
		5% {
			border-color: var(--fm-item-grid-highlight-bg);
		}
		80% {
			border-color: var(--fm-item-grid-highlight-bg);
		}
		100% {
			border-color: var(--fm-item-grid-bg);
		}
	}
}
