.password-page {
	position: relative;
	width: 100%;
	text-align: center;

	.error {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;
	}

	.password-wrapper {
		display: flex;

		@include mobile_small() {
			display: block;
		}
	}

	.password-dialog {
		display: inline-block;
		margin: 200px 0 110px;
		padding: 20px;
		min-width: 140px;
		max-width: 440px;
		width: 70%;

		@include mobile_small() {
			margin-top: 100px;
			margin-bottom: 30px;
		}
	}

	.password-header {
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
		margin-top: 0;
		margin-bottom: 15px;

		@include mobile() {
			white-space: normal;
		}
	}

	.sj-editor {
		flex: 3;

		margin-right: 10px;

		@include mobile_small() {
			margin-right: 0;
			width: 100%;
		}

	}

	.sj-button {
		flex: 1;

		margin-right: 0;

		@include mobile_small() {
			margin-top: 10px;
			width: 100%;
		}
	}
}
