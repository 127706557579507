.import-decryption-key-overlay {
	.sj-overlay-content {
		width: 620px;
	}

	.sj-form-horizontal .sj-form-group .label {
		margin-bottom: var(--gap-s);
	}

	.file-input {
		max-width: 404px;
	}
}
