.uploaddetail-overlay {

	.sj-button-disabled {
		display: none;
	}

	.button-more-uploads {
		// remove whitespace dimensions
		font-size: 0;
	}

	.button-close,
	.button-minimize {
		margin-right: 20px;
	}

	.sj-overlay-content {
		width: 720px;
	}

	.upload-details {
		@include mobile_small {
			display: flex;
			flex-direction: column;
			height: 100%;

			list-layout {
				flex: 1 0 auto;
			}
		}
	}

	.upload-stats {
		margin: 0 0 20px;
		height: 21px;

		.icon-upload-complete,
		.icon-upload-error,
		.icon-upload-close,
		.icon-upload-maximize {
			display: none;
		}

		font-weight: 600;

		.icon-loading {
			color: var(--sfm-upload-status-icon-loading-color);
			font-size: 22px;
			vertical-align: middle;
			margin-right: 10px;
			display: none;
		}

		&.running {
			.icon-loading {
				display: inline-block;
			}
		}

		.uploads-error {
			display: none;
			border-left: 1px solid var(--sfm-upload-detail-overlay-list-border-color);
			padding-left: 14px;
			margin-left: 12px;
			color: var(--sfm-upload-status-error-color);
		}

		&.error {
			.uploads-error {
				display: inline-block;
			}
		}
	}

	list-content {
		display: block;

		max-height: 301px;
		min-height: 120px;
		overflow-y: auto;

		list-item {
			display: block;

			.upload-item {
				position: relative;
				border-bottom: 1px dotted var(--sfm-upload-detail-overlay-list-border-color);
				height: 60px;

				@include mobile {
					height: 100px;
				}
			}

			&:first-child {
				.upload-item {
					border-top: 1px dotted var(--sfm-upload-detail-overlay-list-border-color);
				}
			}
		}
	}

	list-layout {
		display: block;

		background-color: var(--sfm-upload-detail-overlay-list-background);
		margin: 7px 0 49px;
	}

	.upload-item-progress {
		height: 100%;
		width: 100%;
		background-color: var(--sfm-upload-detail-overlay-progress-empty-background);

		position: absolute;

		.bar {
			height: 100%;
			// element style will be set by the view
			width: 0;
		}
	}

	.upload-item-content {
		display: flex;
		align-items: center;

		position: absolute;
		padding: var(--gap-s);

		width: 100%;
		height: 100%;

		box-sizing: border-box;
	}

	.upload-item-icon {
		@include svg-icon-default;
		display: block;
		margin-right: 7px;

		flex: 0 0 40px;
	}

	.upload-item-label {
		display: flex;
		flex: 1 1 50px;
		flex-direction: column;
		justify-content: center;

		color: var(--sfm-upload-detail-overlay-filename-color);

		overflow: hidden;
		min-width: 50px;
	}

	.upload-item-name,
	.upload-item-error {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.upload-item-name,
	.upload-item-error,
	.upload-item-status {
		padding-right: var(--gap-s);
	}

	.upload-item-status,
	.upload-item-error {
		margin-top: 4px;
	}

	.upload-item-error {
		color: var(--sfm-upload-status-error-color);
	}

	.upload-item-size {
		color: var(--sfm-upload-detail-overlay-data-current-amount-color);
		white-space: nowrap;

		.upload-item-progress-amount {
			color: var(--sfm-upload-detail-overlay-data-progress-amount-color);

			@include mobile {
				display: none;
			}
		}
	}

	.upload-item-controls {
		// center small item withing high container
		display: flex;
		align-self: center;
		// center actual, single varying-sized icon within wider container
		align-items: center;
		flex-direction: column;
		justify-content: center;

		// use min-{width,height} 40px to keep space for icons/buttons of different
		// font-size
		min-width: 40px;
		min-height: 40px;

		margin-left: 22px;

		font-size: 16px;

		button {
			display: none;
			margin-right: 0;
		}

		.icon-upload-cancelled {
			color: var(--sfm-upload-detail-overlay-item-icon-cancelled-color);
			display: none;
		}
		.icon-upload-complete {
			color: var(--sfm-upload-detail-overlay-item-icon-complete-color);
			display: none;
		}

	}

	// order matters for the following .upload-status-XYZ .element-class selectors

	.upload-status-prepared, .upload-status-ready, .upload-status-queued, .upload-status-running {
		.upload-item-cancel {
			display: inline;
		}

		.upload-item-progress .bar {
			background-color: var(--sfm-upload-detail-overlay-progress-running-background);
		}
	}

	.upload-status-complete {
		.upload-item-progress .bar {
			background-color: var(--sfm-upload-detail-overlay-progress-complete-background);
		}

		.icon-upload-complete {
			display: inline-block;
		}

	}

	.upload-status-cancelled, .upload-status-failed {
		.upload-item-status {
			display: inline;
		}

		.icon-upload-cancelled {
			display: inline-block;
		}

		.upload-item-progress {
			background-color: var(--sfm-upload-detail-overlay-progress-failed-background);

			.bar {
				background-color: transparent;
			}
		}
	}

	.upload-status-resumable {
		.upload-item-status {
			display: inline;
		}

		.upload-item-resume {
			display: inline-block;
		}

		.upload-item-progress {
			background-color: var(--sfm-upload-detail-overlay-progress-failed-background);

			.bar {
				background-color: transparent;
			}
		}
	}
}
