#header {

	flex: none;

	background-color: var(--hdshare-header-background-color);
	display: inline-block;
	height: $header-height;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 $header-footer-hor-padding;
	// needed for the min-height calculation
	margin-bottom: $header-bottom-space;
	z-index: 5;
	box-sizing: border-box;

	display: flex;
	justify-content: space-between;
	align-items: center;

	.logo {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.right {
		margin-left: 30px;

		.logout {
			display: inline-block;
			cursor: pointer;
			color: var(--hdshare-header-link-color);

			@include hover {
				color: var(--hdshare-header-link-hover-color);
			}

			@include active {
				color: var(--hdshare-header-link-active-color);
			}
		}
	}
}
