@mixin touchable-shim() {
	@include tablet() {
		&:after {
			content: "";
			width: 400%;
			height: 400%;
			position: absolute;
			right: -200%;
			top: -200%;
		}
	}
}

// http://dev.w3.org/csswg/css-flexbox/#min-size-auto
// https://code.google.com/p/chromium/issues/detail?id=426898
// ^^^ not required in chrome yet, but FF breaks
@mixin flex-fix-width() {
	min-width: 0;
}

// Spinning animation
@mixin hdf-spin {
	animation: hdf-spin 2s infinite linear;
}

@keyframes hdf-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

// Overriding The Default Text Selection Color
// https://css-tricks.com/overriding-the-default-text-selection-color-with-css/
@mixin selection() {
	&::-moz-selection{
		@content; /* Gecko Browsers */
	}
	&::selection{
		@content; /* WebKit/Blink Browsers */
	}
}

@mixin placeholder() {
	&::-ms-input-placeholder { /* Edge */
		@content;
	}
	&::placeholder { /* Chrome/Opera/Safari/Firefox */
		/* normalize text opacity on Firefox */
		opacity: 1;
		@content;
	}
}

// generates a text layer to be set on top of pdf thumbnails
// usage:
// @include pdf-icon-thumb {
//	font-size: 12px;
//	border-radius: 4px;
//	...
// }
@mixin pdf-icon-thumb {
	&:after {
		content: "PDF";
		position: absolute;
		background-color: var(--fm-item-preview-icon-background-color);
		left: 0;
		right: 0;
		margin: 0 auto;

		@content;
	}
}

@mixin video-icon-thumb {
	@extend .icon-play-video-thumb;
	display: inherit;
	&:before {
		position: absolute;
		background-color: var(--fm-item-preview-icon-background-color);
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;

		@content;
	}
}

@mixin error-or-empty-view() {
	.error-or-empty-state {
		overflow: hidden;
		height: 100%; /* to make it vertically content-centered */
		min-height: inherit;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items:center;
		align-content: center;

		flex: 0 1 auto;
		pointer-events: none;

		> .image {
			margin: 0 auto;

			svg {
				width: 300px;
				@include mobile {
					width: 200px;
				}
			}
		}

		.text {
			display: block;
			text-align: center;
			margin: 15px auto;

			max-width: 400px;

			h5 {
				color: var(--fm-empty-item-font-color);
				font-weight: 500;
				padding-right: var(--gap-l);
				padding-left: var(--gap-l);
				margin: 0;
			}
		}

		@content;
	}
}

@mixin print() {
	@media only print {
		@content;
	}
}

@mixin display-name-container {
	flex: 1;
	display: flex;
	@content;

	.file-item-displayname {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.file-item-icon {
		display: block;
		margin: 10px 10px 0 10px;
	}
}

@mixin svg-icon-highlighted {
	> svg {
		color: var(--icon-highlight-color);
	}
}

@mixin svg-icon-muted {
	> svg {
		color: var(--icon-muted-color);
	}
}

@mixin svg-icon-default {
	> svg {
		color: var(--icon-default-color);
	}
}

@mixin thumbnail_hover {
	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: var(--fm-item-grid-thumbnail-bg);
	}
}

@mixin iconButtonPosition {
	.icon {
		text-align: right;
		padding-left: 16px;
		padding-right: 8px;
	}
}
