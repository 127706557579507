.sj-info-tooltip {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	padding: 0 10px;
}

// default icon for this component
.icon-info-tooltip {
	color: var(--info-tooltip-icon-color);
	cursor: pointer;

	&:hover {
		color: var(--info-tooltip-icon-hover-color);
	}

	&:active {
		color: var(--info-tooltip-icon-active-color);
	}
}
