@mixin message-base {
	display: inline-block;
	box-sizing: border-box;

	padding: 20px;

	min-width: 250px;
	// not mentioned in styleguide but assumed to be necessary
	max-width: 90%;

	@include non-mobile() {
		width: 420px;
	}

	border-style: solid;
	border-width: 1px;
	border-radius: 2px;
}

.sj-message {
	@include message-base;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	background: var(--message-background-color);
	border: none;
	border-radius: 8px;
	color: var(--message-text-color);
	font-size: 18px;
	padding: 24px;

	&.positive {
		border-left: 20px solid var(--message-positive-border-color);
	}

	&.negative {
		border-left: 20px solid var(--message-negative-border-color);
	}

	&.info {
		border-left: 20px solid var(--message-info-border-color);
	}

	&.attention {
		border-left: 20px solid var(--message-attention-border-color);
	}
}
