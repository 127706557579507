.sj-menu-button {
	display: inline-block;
	position: relative;
	font-size: 0;

	.sj-menu {
		position: absolute;
		margin-top: $flyout-arrow-height;

		min-width: $menu-min-width + (2 * $menu-border-width);
		box-sizing: border-box;
	}
}

.sj-menu-button-open {
	&:before, &:after {
		content: "";
		position: absolute;
		z-index: 10000;
		left: 50%;
		top: 100%;
		margin-left: -#{$flyout-arrow-width*0.5};

		border: {
			left: #{$flyout-arrow-width*0.5} solid transparent;
			bottom: $flyout-arrow-height solid var(--menu-border-color);
			right: #{$flyout-arrow-width*0.5} solid transparent;
		}
	}

	&:after {
		margin-top: 1px;
		border-bottom-color: var(--menu-background-color);
	}
}
