.edit-section {
	max-width: 860px;

	margin-bottom: var(--gap-xxl);
	h3 {
		margin-bottom: var(--gap-m);
		margin-top: 0;
	}

	.sj-form-group:last-child,
	.disabled .sj-form-group:last-child {
		// the margin is only defined in the buttons section to use only a single value
		margin-bottom: 0;
	}

	.edit-area {
		background-color: var(--edit-section-edit-background);
		padding: var(--gap-xl);
		border-radius: $formBorderRadius;

		.buttons {
			padding-top: var(--gap-xl);

			@include mobile {
				.sj-button {
					display: block;
					width: 360px;
					max-width: 100%;

					&:not(:last-child) {
						margin-bottom: var(--gap-l);
					}
				}
			}
		}
	}

	.display-area {
		.edit-button {
			@include mobile {
				display: none;
			}
		}

		.edit-button-mobile {
			width: auto;
			margin-top: var(--gap-l);

			@include iconButtonPosition();

			.label {
				display: inline-block;
			}

			@include non-mobile {
				display: none;
			}
		}
	}
}

.edit-section .wrap-line {
	border-top: var(--sfm-main-section-separator-line-style);
	border-bottom: var(--sfm-main-section-separator-line-style);

	padding: var(--gap-xs) 0;
	min-height: 40px;

	display: flex;

	> .field {
		flex: 1 1 auto;

		&.not-set {
			color: var(--form-input-not-set-color);
		}
	}

	> .edit-button {
		flex: 0 0 auto;

		margin: 0 var(--gap-s);
	}
}


.edit-section.delete {
	.sj-message.attention {
		width: 100%;
	}

	.edit-area {
		display: flex;
		align-items: center;
		padding-bottom: var(--gap-xl);

		.icon {
			margin-right: 27px;
			color: var(--delete-icon-color);
		}

		.message {
			margin-bottom: 3px;
		}
	}
}

