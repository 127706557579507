$menuitem-horizontal-padding: 16px;

.sj-menu {
	position: fixed;
	background-color: var(--menu-background-color);
	border: $menu-border-width solid var(--menu-border-color);
	border-radius: $menu-border-radius;
	box-shadow: var(--menu-shadow);
	overflow-y: auto;
	min-width: $menu-min-width;
	padding: 10px 0;
	z-index: 9999;
	font-size: $base-font-size;

	&.contextmenu {
		.sj-menuitem-disabled {
			display: none;
		}
	}
}

.sj-menu-hidden {
	display: none;
}

.sj-menuitem {
	box-sizing: border-box;
	line-height: 40px;
	height: 40px;
	padding: 0 $menuitem-horizontal-padding;
	color: var(--menu-item-color);
	cursor: pointer;
	display: block;
	font-weight: 600;
	white-space: nowrap;

	&:not(.sj-menuitem-disabled) {
		@include active {
			font-weight: 600;
			color: var(--menu-item-active-color);
			background-color: var(--menu-item-active-background-color);
		}
	}

	.file-input-wrapper {
		input {
			display: none;
		}
	}
}

.sj-menuitem-selected {
	color: var(--menu-item-hover-color);
	background-color: var(--menu-item-hover-background-color);
}

.sj-menuitem-disabled {
	color: var(--menu-item-disabled-color);
	cursor: default;
}

menu-separator {
	display: block;
	border-bottom: 1px solid var(--menu-separator-color);
	margin: 0 $menuitem-horizontal-padding;

	&.hidden {
		display: none;
	}
}
