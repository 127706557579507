.menubar {
	height: $button-height;
	white-space: nowrap;

	.disabled,
	.menubar-separator {
		display: none;
	}

	.sj-icon-button {
		@media only screen and (max-width: 1024px) {
			.app-files & {
				width: $button-height;
			}
		}
	}

	.sj-button {
		margin: 0 0 0 10px;

		.app-files & {
			@include mobile_small {
				display: none;

				&.show-on-mobile-small:not(.disabled) {
					display: inline-block;
				}
			}
		}
	}

	.sj-menu-button, .sj-button {
		// Explicitly define a vertical align so that browsers do not apply a
		// browser stylesheet for a certain tag leading to mixed alignments when
		// button tags are mixed (e.g. label + button). This enforces one
		// vertical alignment across all buttons in a row.
		vertical-align: middle;

		@include non_desktop {
			text-align: center;
		}
	}

	.sj-menu {
		.show-on-mobile-small {
			display: none;
			@include mobile_small {
				display: block;
			}

			&.sj-menuitem-disabled {
				@include mobile_small {
					display: none;
				}
			}
		}
	}
}
