.sj-drop-up-down-flyout {
	.trigger {
		left:0;
		padding: 0;
		border: 1px solid transparent;
		cursor: pointer;
		// center icon and label
		display: flex;
		align-items: baseline;

		color: var(--drop-up-down-flyout-normal-color);

		.label {
			display: inline-block;
			margin: 0 9px 0 8px;
			line-height: 22px;
			border-bottom: 3px solid transparent;
		}

		.arrow {
			width: 8px;
			@extend .icon-flyout-caret-closed;
		}

		@include hover {
			.icon {
				color: var(--drop-up-down-flyout-hover-color);
			}

			.label {
				border-bottom: 3px solid var(--drop-up-down-flyout-hover-color);
			}
		}

		@include active {
			.icon {
				color: var(--drop-up-down-flyout-active-color);
			}

			.label {
				border-bottom: 3px solid var(--drop-up-down-flyout-active-color);
			}
		}
	}

	&.open {
		.trigger {
			.icon {
				color: var(--drop-up-down-flyout-icon-open-color);
			}

			.label {
				border-bottom: 3px solid var(--drop-up-down-flyout-icon-open-color);
			}
		}
	}
}

.sj-drop-down-flyout {
	&.open {
		.trigger {
			.arrow {
				@extend .icon-flyout-caret-dropped-down;
			}
		}
	}
}

.sj-drop-up-flyout {
	.trigger {
		&:before, &:after {
			border-bottom-style: none;
			border-top: $flyout-arrow-height solid var(--flyout-border-color);
			top: -18px;
		}

		&:after {
			border-color: var(--flyout-background-color) transparent;
			top: -20px;
		}
	}

	&.open {
		.trigger {
			.arrow {
				@extend .icon-flyout-caret-dropped-up;
			}
		}
	}
}
