$editorBorderWidth: 1px;
$inputHeight: 44px;
$tagHeight: 24px;

.sj-editor {
	$editorIconSize: 18px;

	display: inline-block;

	> input {
		padding: 0 var(--gap-s);
		border-width: $editorBorderWidth;
		border-radius: $editorBorderRadius;
		height: $inputHeight - 2 * $editorBorderWidth;
	}

	&.sj-editor-icon {
		position: relative;

		/*
		 * if the input follows an .icon, the padding is increased
		 */
		> .icon.left ~ input {
			padding-left: calc(var(--gap-l) + $editorIconSize);
		}
		> .icon.right ~ input,
		> .icon.copy ~ input {
			padding-right: calc(var(--gap-l) + $editorIconSize);
		}
		.icon.right ~ .icon.copy ~ input {
			padding-right: calc(var(--gap-xxl) + 2 * $editorIconSize);
		}
	}

	&.sj-editor-focused .icon {
		color: var(--editor-focused-icon-color);
	}

	.icon {
		top: 50%;
		transform: translateY(-50%);
		width: calc(var(--gap-l) + $editorIconSize);
		height: 100%;
		position: absolute;
		color: var(--editor-icon-color);
		text-shadow: 0 0 4px var(--body-background-color);
		z-index: 1;

		display: flex;
		align-items: center;
		justify-content: center;

		&.right,
		&.copy {
			right: 0;
		}

		&.right ~ .icon.copy {
			right: calc(var(--gap-l) + $editorIconSize);
		}

		&.clickable {
			cursor: pointer;
		}
	}

	.icon-validation-busy {
		color: var(--editor-validation-busy-icon-color);
	}

	.icon-validation-error {
		color: var(--editor-validation-error-icon-color);
	}
}

%sj-editor-focus-border {
	border-color: var(--text-editor-focused-border-color);
	box-shadow: var(--text-editor-focused-shadow);
}

.sj-text-editor {
	background-color: var(--text-editor-background-color);
	border: $editorBorderWidth solid var(--text-editor-border-color);
	border-radius: $editorBorderRadius;
	outline: none;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	box-shadow: var(--text-editor-shadow);

	&.sj-editor-focused {
		@extend %sj-editor-focus-border;
	}

	&.sj-editor-disabled {
		background-color: var(--text-editor-disabled-background-color);

		input {
			color: var(--text-editor-disabled-color);

			@include placeholder {
				color: var(--text-editor-disabled-color);
			}
		}
	}

	input {
		width: 100%;
		height: $inputHeight - $editorBorderWidth * 2;
		box-sizing: border-box;
		border: none;
		outline: none;
		background-color: transparent;
		color: var(--editor-text-color);

		@include placeholder {
			color: var(--editor-placeholder-color);
		}

		@include selection {
			color: var(--editor-selection-color);
			background-color:var(--editor-selection-background-color);
		}
	}

	&.error {
		border-color: var(--text-editor-error-border-color);

		&.sj-editor-focused {
			box-shadow: var(--text-editor-error-focused-shadow);
		}
	}
}

.sj-editor.taglist {
	position: relative;
	padding-top: ($inputHeight - $tagHeight) * 0.5;
	border-radius: $editorBorderRadius $editorBorderRadius 0 0;

	input, .tag, button {
		background-color: transparent;
		border: none;
		outline: none;
		font-size: $base-font-size;
		line-height: 1.25;
	}

	.tag, button {
		color: var(--tag-text-color);
	}

	input {
		color: var(--tag-editor-color);
	}

	// original hover color makes the tags seem transparent
	@include hover {
		background-color: var(--text-editor-background-color);
	}

	display: flex;
	flex-wrap: wrap;

	.tag {
		flex: none;
		display: flex;
		align-items: center;
		flex-direction: row;
		height: $tagHeight;
		background-color: var(--tag-background-color);
		margin-bottom: ($inputHeight - $tagHeight) * 0.5;
		margin-left: 5px;
		border-radius: 2px;
		padding: 0 10px;
		white-space: nowrap;

		@include hover {
			background-color: var(--tag-hover-background-color);
		}

		&:not(.tag-focused) {
			@include hover {
				button {
					color: var(--tag-hover-text-color);
				}
			}
		}

		button {
			padding: 0;
			margin: 0;
			max-width: 300px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.icon-removetag {
			font-size: 12px;
			margin-left: 10px;
			color: var(--tag-remove-color);
			cursor: pointer;
			position: relative;

			@include hover {
				color: var(--tag-remove-hover-color);
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transform: scale(2);
			}
		}

		&.tag-focused {
			background-color: var(--tag-focussed-background-color);

			.icon-removetag {
				color: var(--tag-focussed-remove-color);

				@include hover {
					color: var(--tag-focussed-remove-hover-color);
				}
			}
		}
	}

	input[type=text] {
		flex: 1;

		padding: 0 var(--gap-s);
		margin-bottom: 5px;
		height: 26px;
		min-width: 150px;

		&::-ms-clear {
			display: none;
		}
	}
}

textarea {
	box-sizing: border-box;
	padding: 10px 8px;
	border: $editorBorderWidth solid var(--text-editor-border-color);
	color: var(--body-text-color);
	border-radius: $editorBorderRadius;
	margin: 0;
	background-color: var(--text-editor-background-color);

	&:focus {
		@extend %sj-editor-focus-border;
	}
}
