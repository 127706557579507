.error-page {
	position: relative;
	width: 100%;
	height: auto;
	text-align: center;
	padding-top: 50px;

	@include mobile() {
		padding-top: 20px;
	}

	h2 {
		margin: 0 20px;
	}

	.wrapper {

		width: 400px;
		margin: 50px auto 0;
		@include mobile_small() {
			max-width: 90%;
		}

		.error {
			padding-bottom: 80%; /*for image 400px x 320px: 80=320/400*100 */
		}

		.error-image {
			max-width: 250px;
			margin: auto;
		}
	}
}
