.sj-overlay {
	position: fixed;
	left: 0;
	top: 0;
	bottom: -2px;
	right: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 30px;

	@include mobile_small {
		bottom: 0;

		&:not(.confirm-overlay):not(.alert-overlay) {
			padding: 0;
		}
	}

	&:focus {
		outline: none;
	}
}

.sj-overlay-cover {
	background-color: var(--overlay-cover-color);
	z-index: -1;

	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.sj-overlay-content {
	position: relative;
	border: 1px solid var(--overlay-border-color);
	align-self: center;
	border-radius: $menu-border-radius;
	background-color: var(--overlay-content-color);
	width: 420px;
	max-width: 100%;

	@include mobile_small {
		border-radius: 0;
		width: 100vw;
		height: 100%;
	}


	.sj-overlay-body {
		padding: 29px 29px 29px;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 100vh;
		box-sizing: border-box;
		height: 100%;

		position: relative;
	}
}

.sj-overlay-loading-cover {
	display: none;
}

.sj-overlay-loading .sj-overlay-loading-cover {
	display: flex;
	align-items: center;
	position: absolute;

	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background-color: var(--overlay-loading-cover-color);

	.icon {
		margin: 0 auto;
		color: var(--overlay-loading-icon-color);
	}
}

.sj-overlay-close {
	position: absolute;
	right: 1px;
	top: 0;
	width: 35px;
	height: 35px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--overlay-close-icon-color);

	@include hover {
		color: var(--overlay-close-icon-hover-color);
	}

	@include active {
		color: var(--overlay-close-icon-active-color);
	}

	@include touchable-shim();
}

.sj-overlay .overlay-title {
	font: $overlay-title-font;
	line-height: 44px;
	color: var(--overlay-title-color);

	display: inline-block;
	width: 100%;
	margin-bottom: 30px;
	flex: 0 0 auto;
}

.sj-overlay .overlay-subject-name {
	font: $overlay-subject-font;
	font-weight: 500;
	color: var(--overlay-title-color);

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.sj-overlay .overlay-text {
	margin-right: 10px;
}

.sj-overlay .overlay-buttons {
	margin: 40px 0 0;

	.sj-button {
		&:last-child {
			margin-right: 0;
		}

		@include mobile_small {
			width: 100%;
		}
	}

	@include mobile_small {
		.sj-button {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}

.sj-overlay-hidden {
	display: none;
}

.sj-wait-overlay {
	.sj-overlay-body {
		margin-bottom: 10px;
	}

	.wait-overlay-body {
		display: flex;
		align-items: center;
	}

	.icon {
		color: var(--wait-overlay-loading-icon-color);
		margin-right: 30px;
		vertical-align: middle;
	}
}

.sj-overlay-error {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: 5px;
	z-index: 3;
	text-align: center;
	background-color: var(--overlay-error-background-color);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> * {
		margin-bottom: 30px;
	}

	.icon {
		display: block;
		color: var(--overlay-error-icon-color);
	}

	.sj-button {
		margin: 0;
	}

	p {
		margin-left: auto;
		margin-right: auto;
		width: 240px;
	}
}

.confirm-overlay,
.alert-overlay {
	.sj-overlay-content {
		@include mobile_small {
			border-radius: 8px;
			height: auto;
		}
	}
}

.confirm-overlay {
	.affirm {
		cursor: pointer;

		display: flex;
		align-items: center;

		.icon-validation-error-big {
			visibility: hidden;

			margin-left: 10px;

			color: var(--editor-validation-error-icon-color);
		}
	}

	&.has-error {
		.affirm > .icon-validation-error-big {
			visibility: visible;
		}
	}
}

@include print {
	.sj-overlay {
		display: block;
		padding: 0;
	}

	.sj-overlay-cover,
	.sj-overlay-close {
		display: none;
	}

	.sj-overlay-content {
		border: none;

		.sj-overlay-body {
			padding: 0;

			overflow: hidden;
		}
	}
}

@mixin shimmed-close-button {
	.sj-overlay-close {
		background-color: var(--overlay-close-white-background-color);
		width: 40px;
		height: 40px;
		border-radius: 40px;
		right: 10px;
		top: 10px;

		box-sizing: border-box;
		padding-bottom: 2px;

		color: var(--overlay-close-icon-on-white-background-color);

		@include hover {
			color: var(--overlay-close-icon-on-white-background-hover-color);
		}

		@include active {
			color: var(--overlay-close-icon-on-white-background-active-color);
		}

		@content;
	}
}
