$fv-detail-width: 280px;
$fv-detail-space: 20px;

.detailView {
	background-color: var(--fv-detail-background-color);
	width: 0px;
	position: relative;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-ms-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	overflow-y: auto;
	overflow-x: hidden;

	h2, h4, p {
		width: $fv-detail-width;
	}

	h2 {
		color: var(--fv-detail-header-color);
		margin-bottom: 0;
	}

	h4 {
		color: var(--fv-detail-sub-header-color);
		margin: 20px 0 0 0;
	}

	p {
		color: var(--fv-detail-text-color);
		line-height: 20px;
		margin: 0;
	}

	&.active {
		padding: 0px $fv-detail-space ($fv_status_height + $fv-detail-space) 20px;
		width: $fv-detail-width;
	}

	.icon-map-marker {
		margin-right: 0.5em;
	}
}

.detailview-active{
	.filesviewer-overlay-close {
		margin-right: $fv-detail-width + ($fv-detail-space * 2);
	}

	@include mobile(){
		.detailView.active {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}

	a {
		color: var(--link-alternative-color);

		&:hover {
			color: var(--link-alternative-hover-color);
		}
	}
}
