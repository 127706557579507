.officeview {
	@extend .fileview;

	&.has-preview {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		justify-content: flex-end;
		padding-bottom: 20px;;
	}

	.sj-button {
		background-color: var(--fv-button-background-color);
		border-color: var(--fv-button-border-color);
		color: var(--fv-button-text-color);

		@include hover {
			background-color: var(--fv-button-hover-background-color);
			border-color: var(--fv-button-hover-border-color);
			color: var(--fv-button-hover-text-color);
		}

		@include active {
			background-color: var(--fv-button-active-background-color);
			border-color: var(--fv-button-active-border-color);
			color: var(--fv-button-active-text-color);
		}
	}
}
