$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss"; // needed for platform icons in device backups
@import "breakpoints";
@import "./svg-icons";

// Mapping of icons-names to font-awesome variables containing the font-characters
$fontIconMap: (
	icon-cookiebanner-close: $fa-var-times-circle,
	icon-logout: $fa-var-sign-out,
	icon-upload: $fa-var-upload,
	icon-burger: $fa-var-bars,
	icon-database: $fa-var-database,
	icon-drawer-close: $fa-var-close,
	icon-download-selection: $fa-var-download,
	icon-download-folder: $fa-var-download,
	icon-toolbar-download: $fa-var-download,
	icon-share-folder: $fa-var-share-alt,
	icon-share-item: $fa-var-share-alt,
	icon-new-folder: $fa-var-plus,
	icon-new-teamfolder: $fa-var-plus,
	icon-invite-user: $fa-var-plus,
	icon-unselectall: $fa-var-times,
	icon-more: $fa-var-ellipsis-h,
	icon-user: $fa-var-user,
	icon-email: $fa-var-envelope,
	icon-email-confirmed: $fa-var-check-circle,
	icon-email-not-confirmed: $fa-var-exclamation-triangle,
	icon-email-send-confirmation: $fa-var-paper-plane,
	icon-password: $fa-var-lock,
	icon-password-protected: $fa-var-lock,
	icon-loading: $fa-var-circle-notch,
	icon-delete-share: $fa-var-trash-can,
	icon-delete-shares: $fa-var-trash-can,
	icon-edit-share: $fa-var-pencil,
	icon-edit-mu: $fa-var-pencil,
	icon-breadcrumb-arrow: $fa-var-caret-right,
	icon-metainfo-shared: $fa-var-share-alt,
	icon-checkbox-checked: $fa-var-check,
	icon-toast-close: $fa-var-times,
	icon-overlay-close: $fa-var-times,
	icon-overlay-inverted-close: $fa-var-times-circle,
	icon-overlay-error: $fa-var-exclamation-triangle,
	icon-places-settings: $fa-var-gear,
	icon-upload-error: $fa-var-exclamation,
	icon-upload-cancel: $fa-var-times,
	icon-upload-complete: $fa-var-check,
	icon-upload-cancelled: $fa-var-exclamation-triangle,
	icon-upload-maximize: $fa-var-expand,
	icon-upload-resume: $fa-var-refresh,
	icon-uploading: $fa-var-upload,
	icon-upload-close: $fa-var-times,
	icon-search-clear-input: $fa-var-times,
	icon-shareoverlay-preview: $fa-var-external-link,
	icon-shareoverlay-mail: $fa-var-envelope,
	icon-shareoverlay-delete: $fa-var-trash-can,
	icon-shareoverlay-edit: $fa-var-cog,
	icon-item-selector: $fa-var-check,
	icon-item-selected: $fa-var-check-square,
	icon-item-shared: $fa-var-share-alt,
	icon-view-tiles: $fa-var-th,
	icon-view-list: $fa-var-th-list,
	icon-view-fluid: $fa-var-image,
	icon-list-ascending: $fa-var-caret-up,
	icon-list-descending: $fa-var-caret-down,
	icon-sort: $fa-var-arrow-right-arrow-left,
	icon-sort-ascending: $fa-var-caret-up,
	icon-sort-descending: $fa-var-caret-down,
	icon-filesviewer-close: $fa-var-times-circle,
	icon-filesviewer-delete: $fa-var-trash-can,
	icon-filesviewer-download: $fa-var-cloud-download,
	icon-filesviewer-info: $fa-var-info-circle,
	icon-filesviewer-pause: $fa-var-pause,
	icon-filesviewer-play: $fa-var-play,
	icon-filesviewer-previous: $fa-var-caret-left,
	icon-filesviewer-next: $fa-var-caret-right,
	icon-filesviewer-pdf-prev: $fa-var-chevron-up,
	icon-filesviewer-pdf-next: $fa-var-chevron-down,
	icon-validation-busy: $fa-var-circle-notch,
	icon-validation-error: $fa-var-exclamation-triangle,
	icon-validation-error-big: $fa-var-exclamation-triangle,
	icon-info-tooltip: $fa-var-info-circle,
	icon-language: $fa-var-flag,
	icon-language-selected: $fa-var-check,
	icon-flyout-caret-closed: $fa-var-angle-right,
	icon-flyout-caret-dropped-up: $fa-var-angle-up,
	icon-flyout-caret-dropped-down: $fa-var-angle-down,
	icon-navigation-caret-closed: $fa-var-caret-right,
	icon-navigation-caret-open: $fa-var-caret-down,
	icon-button-close: $fa-var-circle-xmark,
	icon-edit: $fa-var-pencil,
	icon-removetag: $fa-var-times,
	icon-sendmail: $fa-var-paper-plane,
	icon-emaildirty-edit: $fa-var-pencil,
	icon-back-to-login: $fa-var-arrow-left,
	icon-dropdown: $fa-var-angle-down,
	icon-users-warning: $fa-var-exclamation-circle,
	icon-user-delete: $fa-var-trash-can,
	icon-user-delete-section: $fa-var-trash-can,
	icon-checked: $fa-var-check,
	icon-unchecked: $fa-var-times,
	icon-test-successful: $fa-var-check-circle,
	icon-test-failed: $fa-var-times,
	icon-sshkey-delete: $fa-var-trash-can,
	icon-app-mobile: $fa-var-mobile,
	icon-app-computer: $fa-var-desktop,
	icon-app-delete: $fa-var-trash-can,
	icon-delete-snapshot: $fa-var-trash-can,
	icon-open-snapshot: $fa-var-undo,
	icon-restore-snapshot: $fa-var-undo,
	icon-search: $fa-var-search,
	icon-search-cancel: $fa-var-times-circle,
	icon-search-cancel-big: $fa-var-times,
	icon-devicebackup-delete: $fa-var-trash-can,
	icon-devicebackup-download: $fa-var-arrow-down,
	icon-devicebackup-platform-android: $fa-var-android,
	icon-devicebackup-platform-apple: $fa-var-apple,
	icon-devicebackup-platform-unknown: $fa-var-desktop,
	icon-devicebackup-platform-windows: $fa-var-windows,
	icon-setup-done: $fa-var-check-circle,
	icon-slideshow-previous: $fa-var-angle-left,
	icon-slideshow-next: $fa-var-angle-right,
	icon-star: $fa-var-star,
	icon-feedback-success: $fa-var-thumbs-up,
	icon-copy: $fa-var-copy,
	icon-view: $fa-var-eye,
	icon-two-fa-enable-success: $fa-var-check-circle,
	icon-two-fa-recover-success: $fa-var-check-circle,
	icon-user-secret: $fa-var-user-secret,
	icon-level-up: $fa-var-level-up,
	icon-folder-flyout-arrow: $fa-var-angle-down,
	icon-map-marker: $fa-var-map-marker,
	icon-play-video-thumb: $fa-var-play,
	icon-privacy-link: $fa-var-arrow-right,
	icon-privacy-more: $fa-var-caret-down,
	icon-privacy-less: $fa-var-caret-up,
	icon-privacy-back: $fa-var-arrow-left,
	icon-teamfolder-delete-section: $fa-var-trash-can,
	icon-topbar-hide: $fa-var-caret-up,
);

// Core placeholder class for all font-icons
%font-icon {
	display: inline-block;
	font: normal normal normal 14px/1 "Font Awesome 6 Free"; // shortening font declaration
	text-rendering: auto; // optimizelegibility throws things off #1094
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Map all font icons to the font-awesome content strings
// ------------------------------------------------------
@each $iconName, $fontContent in $fontIconMap {
	.#{$iconName} {
		@extend %font-icon;

		&:before {
			@extend .fa-solid;
			content: unquote('"#{$fontContent}"');
		}
	}
}

.icon-burger {
	font-size: 20px;
}

.icon-drawer-close {
	font-size: 20px;
}

// Overrides
// ---------
.icon-logout {
	font-size: 16px;
}

.icon-loading {
	&:before {
		@include hdf-spin;
	}

	font-size: var(--loading-icon-font-size);
}

.icon-upload {
	font-size: 16px;
}

.icon-uploading {
	font-size: 28px;
}

.icon-upload-close {
	font-size: 22px;
}

.icon-search-clear-input {
	font-size: 18px;
}

.icon-upload-maximize {
	font-size: 22px;
}

.icon-download-selection {
	font-size: 20px;
}

.icon-download-folder {
	font-size: 20px;
}

.icon-share-folder {
	font-size: 20px;
}

.icon-share-item {
	font-size: 20px;
}

.icon-new-folder {
	font-size: 20px;
}

.icon-new-teamfolder {
	font-size: 16px;
}

.icon-invite-user {
	font-size: 16px;
}

.icon-checkbox-checked {
	font-size: 18px;
}

.icon-more {
	font-size: 20px;
}

.icon-unselectall {
	font-size: 22px;
}

.icon-delete-shares {
	font-size: 20px;
}

.icon-delete-share {
	font-size: 20px;
}

.icon-edit-mu  {
	font-size: 22px;
}

.icon-edit-share {
	font-size: 20px;
}

.icon-breadcrumb-arrow {
	font-size: 14px;
}

.icon-toast-close {
	font-size: 18px;
}

.icon-overlay-close {
	font-size: 22px;
}

.icon-overlay-inverted-close {
	font-size: 22px;
}

.icon-overlay-error {
	font-size: 60px;
}

.icon-places-settings {
	font-size: 20px;
}

.icon-shareoverlay-preview {
	font-size: 20px;
}

.icon-shareoverlay-mail {
	font-size: 20px;

	&:before {
		@extend .fa-regular;
	}
}

.icon-shareoverlay-delete {
	font-size: 16px;
}

.icon-shareoverlay-edit {
	font-size: 16px;
}

.icon-item-selector {
	font-size: 18px;
}

.icon-item-selected {
	font-size: 26px;
}

.icon-list-ascending,
.icon-list-descending {
	font-size: 22px;
}

.icon-sort {
	font-size: 19px;
	transform: rotate(90deg);
}

.icon-sort-ascending,
.icon-sort-descending {
	font-size: 24px;
}

.icon-toolbar-download {
	font-size: 18px;
}

.icon-user,
.icon-email {
	font-size: 18px;
}

.icon-password {
	font-size: 12px;
}

.icon-password-protected {
	font-size: 12px;
}

.icon-filesviewer-close {
	font-size: 22px;
}

.icon-filesviewer-delete,
.icon-filesviewer-download,
.icon-filesviewer-info {
	font-size: 22px;
}

.icon-filesviewer-previous,
.icon-filesviewer-next {
	font-size: 36px;
}

.icon-filesviewer-pause,
.icon-filesviewer-play {
	font-size: 24px;
}

.icon-validation-busy {
	@include hdf-spin;

	font-size: 18px;
}

.icon-validation-error {
	font-size: 18px;
}

.icon-validation-error-big {
	font-size: 28px;
}

.icon-language {
	font-size: 14px;
}

.icon-language-selected {
	font-size: 12px;
}

.icon-flyout-caret-closed,
.icon-flyout-caret-dropped-up,
.icon-flyout-caret-dropped-down {
	font-size: 14px;
}

.icon-info-tooltip {
	font-size: 18px;
}

.icon-edit {
	font-size: 20px;
}

.icon-cookiebanner-close {
	font-size: 18px;
}

.icon-metainfo-shared {
	font-size: 14px;
}

.icon-filesviewer-pdf-next {
	font-size: 14px;
}

.icon-filesviewer-pdf-prev {
	font-size: 14px;
}

.icon-item-shared {
	font-size: 14px;
}

.icon-targetdialog {
	font-size: 22px;
}

.icon-upload-cancel {
	font-size: 16px;
}

.icon-upload-cancelled {
	font-size: 16px;
}

.icon-upload-complete {
	font-size: 28px;
}

.icon-upload-error {
	font-size: 28px;
}

.icon-upload-resume {
	font-size: 16px;
}

.icon-view,
.icon-view-tiles,
.icon-view-list,
.icon-view-fluid {
	font-size: 20px;
}

.icon-view-fluid:before {
	@extend .fa-regular;
}

.icon-emaildirty-edit {
	font-size: 16px;
}

.icon-back-to-login {
	font-size: 18px;
}

.icon-database {
	font-size: 16px;
}

.icon-dropdown {
	font-size: 16px;
}

.icon-users-warning {
	font-size: 22px;
}

.icon-user-delete {
	font-size: 18px;
}

.icon-user-delete-section {
	font-size: 42px;
}

.icon-checked {
	font-size: 20px;
}

.icon-unchecked {
	font-size: 20px;
}

.icon-sshkey-delete {
	font-size: 20px;
}

.icon-app-mobile {
	font-size: 30px;
}

.icon-app-computer {
	font-size: 26px;
}

.icon-app-delete {
	font-size: 20px;
}

.icon-open-snapshot {
	font-size: 20px;
}

.icon-delete-snapshot {
	font-size: 20px;
}

.icon-restore-snapshot {
	font-size: 20px;
}

.icon-search {
	font-size: 16px;
}

.icon-search-cancel {
	font-size: 16px;
}

.icon-search-cancel-big {
	font-size: 50px;
}

.icon-devicebackup-delete {
	font-size: 20px;
}

.icon-devicebackup-download {
	font-size: 20px;
}

.icon-devicebackup-platform-android {
	font-size: 28px;

	&:before {
		@extend .fa-brands;
	}
}

.icon-devicebackup-platform-apple {
	font-size: 28px;

	&:before {
		@extend .fa-brands;
	}
}

.icon-devicebackup-platform-unknown {
	font-size: 26px;
}
.icon-devicebackup-platform-windows {
	font-size: 28px;

	&:before {
		@extend .fa-brands;
	}
}
.icon-setup-done {
	font-size: 120px;
}

.icon-slideshow-previous {
	font-size: 30px;
}

.icon-slideshow-next {
	font-size: 30px;
}

.icon-star {
	font-size: 24px;
}

.icon-copy {
	font-size: 20px;
}

.icon-feedback-success {
	font-size: 40px;
}

.icon-two-fa-enable-success {
	font-size: 90px;
}

.icon-two-fa-recover-success {
	font-size: 120px;
}

.icon-user-secret {
	font-size: 22px;
}

.icon-level-up {
	font-size: 20px;
	transform: scale(-1, 1);
}

.icon-folder-flyout-arrow {
	font-size: 14px;
}

.icon-map-marker {
	font-size: 14px;
}

.icon-privacy-link {
	font-size: 10px;
}

.icon-privacy-more {
	font-size: 14px;
}

.icon-privacy-less {
	font-size: 14px;
}

.icon-privacy-back {
	font-size: 20px;
}

.icon-teamfolder-delete-section {
	font-size: 42px;
}

.icon-test-successful,
.icon-test-failed {
	font-size: 130px;
}
