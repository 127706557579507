
.sj-thumbnail {
	background-repeat: no-repeat;
	background-position: center center;
	// failsafe - the view should actually calculate the container size
	background-size: contain;

	&.sj-thumbnail-loading {
		@extend .icon-loading;

		color: var(--thumbnail-loader-icon-color);
	}
}
