.toolbar {
	display: none;

	@include mobile {
		// align rightmost icon with 'more' button
		margin-right: 11px;
	}

	&.active {
		display: flex;
		align-items: center;
	}

	.sj-flyout {
		> .trigger {
			cursor: pointer;
			display: flex;

			.icon {
				color: var(--fm-toolbar-item-color);
			}

			.label {
				display: none;
				color: var(--fm-toolbar-item-color);
				font-weight: 600;

				@include desktop {
					display: inline;
					padding: 0 0 0 10px;
				}
			}

			&:hover {
				.icon {
					color: var(--fm-toolbar-item-hover-color);
				}

				.label {
					color: var(--fm-toolbar-item-hover-color);
				}
			}

			&:active {
				.icon {
					color: var(--fm-toolbar-item-active-color);
				}

				.label {
					color: var(--fm-toolbar-item-active-color);
				}
			}
		}

		// negate the spacing of the menubar-separator in order to have the menu and
		// flyout-arrow line up with the icon.
		> .content {
			margin-right: -10px;

			@include desktop {
				margin-right: 0;
			}
		}
	}

	.fm-viewmode-switcher {
		display: flex;

		@include mobile {
			display: none;
		}

		.icon-container {
			// visually center the icons in the toolbar
			height: 20px;
		}

		.mode {
			cursor: pointer;

			&:not(:last-child) {
				// UX: must be 24px
				margin-right: 24px;
			}

			.icon {
				color: var(--fm-toolbar-item-color);
			}

			&.selected {
				cursor: default;
				.icon {
					color: var(--fm-toolbar-item-selected-color);
				}
			}

			&:not(.selected) {
				&:hover {
					.icon {
						color: var(--fm-toolbar-item-hover-color);
					}
				}

				&:active {
					.icon {
						color: var(--fm-toolbar-item-active-color);
					}
				}
			}
		}
	}

	.fm-viewmode-flyout {
		display: none;

		+ .menubar-separator {
			// Hide separator belonging to the flyout:
			// Either viewmode-flyout or viewmode-switcher are be displayed as
			// last element depending on viewport size.
			display: none;
		}

		@include mobile {
			display: block;
		}
	}

	.menubar-separator {
		height: 20px;
		background-color: var(--fm-toolbar-separator-color);
		// UX: must be 24px
		margin: 0 24px;
		box-sizing: border-box;
		width: 1px;
	}
}
