.uploaddetail-overlay {
	.overlay-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;

		> .sj-button {
			margin-right: var(--gap-s);
			@include mobile_small {
				margin-right: 0;
			}
		}

		> .spacer {
			margin: 0 auto;
		}
	}

	.button-close {
		margin-right: 0;
	}

	@include mobile_small {
		.button-close,
		.button-more-uploads {
			margin-bottom: 10px;
		}
	}

	.button-cancel-all {
		line-height: 40px;
	}

	// show text button, override media query to show text
	// only if width > 839px
	.button-more-uploads .sj-icon-button {
		width: auto;
		.label {
			display: inline-block;
		}
	}

	.button-more-uploads {
		margin-right: var(--gap-s);

		@include iconButtonPosition();

		@include mobile_small {
			width: 100%;
			margin-right: 0;
			margin-bottom: 0;

			.sj-icon-button {
				width: 100%;
				text-align: center;
			}
		}
	}
}
