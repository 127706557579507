$fv_status_height: 70px;

@import "./fv_view";
@import "./fv_image";
@import "./fv_video";
@import "./fv_audio";
@import "./fv_text";
@import "./fv_pdf";
@import "./fv_detail";
@import "./fv_office";

@import "./fv_overlay";
@import "./fv_base";
@import "./fv_status";

// common extend-only base class for all icon-only buttons inside FilesViewer
%filesviewer-icon-button {
	cursor: pointer;
	color: var(--fv-link-color);
	background-color: transparent;
	border: none;
	outline: none; // Remove focus outline

	@include hover {
		color: var(--fv-link-hover-color);
	}

	@include active {
		color: var(--fv-link-active-color);
	}
}

.filesviewer-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -.5em;
	margin-left: -.5em;
	color: var(--fv-loading-icon-color);

	&:not(.loading) {
		display: none;
	}
}
