#footer {
	flex: none;
	display: flex;
	align-items: center;
	width: 100%;
	height: $footer-height;
	padding: 0 $header-footer-hor-padding;
	background: var(--hdshare-footer-background-color);
	z-index: 5;
	box-sizing: border-box;

	.simple-separator {
		display: inline-block;
		height: 15px;
		margin: 0 6px -2px;
		border-left: 1px solid var(--hdshare-footer-separator-border-left-color);
	}

	.sj-lang-flyout {
		.trigger .label {
			font-size: $base-font-size;
		}

		.content {
			top: auto;
			bottom: 44px;
			left: -10px;
			right: auto;
		}
	}

	.links {
		flex: 1;

		a {
			padding-bottom: 2px;
			outline: 0;
			text-decoration: none;
			font-weight: normal;
			color: var(--hdshare-footer-link-color);

			@include active {
				color: var(--hdshare-footer-link-active-color);
				border-bottom: var(--hdshare-footer-link-active-underline-color) solid 3px;
			}

			@include hover {
				color: var(--hdshare-footer-link-hover-color);
				border-bottom: var(--hdshare-footer-link-hover-underline-color) solid 3px;
			}
		}
	}
	.powered_by {
		color: var(--hdshare-footer-link-color);

		@include hover {
			color: var(--hdshare-footer-link-hover-color);
		}
	}
}
