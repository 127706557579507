
.sj-tooltip {
	$tip_height: 15px;

	position: absolute;
	max-width: 330px;

	// space for the tip, used by the view to determine top offset
	margin-bottom: $tip_height;

	background: var(--tooltip-background-color);
	border: 1px solid var(--tooltip-border-color);
	border-radius: $menu-border-radius;
	box-shadow: 0px 3px 4px 0px var(--tooltip-shadow-color);

	.sj-tooltip-body {
		padding: 16px;

		ul {
			list-style-type: disc;
			padding: 10px 20px;
		}
	}

	.sj-tooltip-tip {
		position: absolute;
		bottom: -($tip_height - 1px);
		left: 10px;

		height: $tip_height;

		&:before {
			content: "";
			position: absolute;
			z-index: 1;
			border-style: solid solid none solid;
			border-width: $tip_height 9px;
			border-color: var(--tooltip-tip-before-color) transparent;
			left: 0px;
			top: 2px;
		}

		&:after {
			content: "";
			position: absolute;
			z-index: 1;
			border-style: solid solid none solid;
			border-width: $tip_height 9px;
			border-color: var(--tooltip-tip-after-color) transparent;
		}
	}
}
