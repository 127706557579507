.sj-starwars-toggle {
	vertical-align: middle;
	width: 32px;
	height: 32px;

	input {
		margin: 0;
		z-index: 2;
		cursor: pointer;
	}

	span {
		/* OFF */
		@extend .icon-darthvader-off;

		&:before, &:after {
			height: 0px;
			width: 0px;

			border: none;

			content: "";
		}
	}

	input:checked ~ span {
		/* ON */
		@extend .icon-luke-off;

		&:before {
			content: "";

			border: none;
		}
	}

	&:hover {
		span {
			@extend .icon-darthvader;
		}

		input:checked ~ span {
			@extend .icon-luke;
		}
	}
}
