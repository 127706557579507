x-filemanager {
	margin: #{$fm-dropzone-border-size};

	.empty-view {
		margin-top: -60px;
	}

	list-header {
		// negate dropzone
		margin-top: -$fm-dropzone-border-size;
		padding: 0 calc($fm-dropzone-size - $fm-dropzone-border-size);

		left: $content-side-margin;
		right: $content-side-margin;
	}

	list-content {
		padding-left: calc($fm-dropzone-size - $fm-dropzone-border-size);
		padding-right: calc($fm-dropzone-size - $fm-dropzone-border-size);
		padding-bottom: calc($fm-dropzone-size - $fm-dropzone-border-size);
	}

	list-content, tile-content, fluid-content {
		// enables file drag&drop
		transform: translateY(0);
	}
}
