.consent-overlay {
	.sj-overlay-content {
		width: 600px;
	}
}

consent-intro {
	display: flex;
	flex-direction: row;

	.sj-lang-flyout {
		margin-bottom: 32px;

		.content {
			right:auto;
			left: -10px;
		}
	}

	.image {
		padding: 0 20px;

		@include mobile_small() {
			display: none;
		}
	}

	.external-links {
		@include mobile_small() {
			display: inline-block;
		}

		a {
			display: inline-flex;
			align-items: center;

			margin-right: 10px;

			.arrow {
				margin-right: 4px;
			}

			@include mobile_small() {
				display: block;
				// increase clickable area
				padding: 10px 0;
			}
		}
	}
}

consent-settings-header {
	display: flex;
	align-items: center;
	margin: $h1-margin;

	.icon-privacy-back {
		@extend %icon-link;

		// increase clickable area
		padding: 10px;
		margin-left: -10px;
	}

	h1 {
		margin: 0;
	}
}

consent-settings-part {
	display: flex;
	flex-direction: row-reverse;
	padding-bottom: 5px;
	margin-bottom: 5px;

	consent-settings-text {
		flex-grow: 1;
	}

	&:not(:last-of-type) {
		@include mobile_small() {
			border-bottom: 1px solid #ddd;
		}
	}

	h2 {
		margin-top: 0;
	}

	p {
		margin: 0;
	}

	&:not(.more) {
		consent-settings-text > p {
			display: none;
		}

		a.less {
			display: none;
		}
	}

	&.more {
		a.more {
			display: none;
		}
	}
}
