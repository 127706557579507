.filesviewer-overlay {
	bottom: 0;

	background-color: var(--fv-overlay-background);
	color: var(--fv-overlay-text-color);
}

.filesviewer-overlay-close {
	position: absolute;
	right: $fvHeaderPaddingRight - $fvCloseButtonBorder - $fvCloseButtonPadding;
	top: ($fvHeaderHeight - 22px) * 0.5 - $fvCloseButtonBorder;
	z-index: 9999;
	cursor: pointer;
	color: var(--fv-overlay-close-color);
	background: var(--fv-overlay-background);
	border: $fvCloseButtonBorder solid var(--fv-overlay-background);
	border-radius: 100%;
	padding: 0 $fvCloseButtonPadding;

	@include hover {
		color: var(--fv-overlay-close-hover-color);
	}

	@include active {
		color: var(--fv-overlay-close-active-color);
	}
}
