.fm-sort-flyout {
	.sort-indicators {
		position: absolute;
		right: 10px;
		top: (40px - 26px) * 0.5;
		height: 26px;
		font-size: 0;
		color: transparent;
	}

	.icon-sort-ascending,
	.icon-sort-descending {
		width: 1em;
		text-align: center;
	}

	.icon-sort-descending {
		vertical-align: text-bottom;
	}

	li {
		position: relative;
		// reserve space for sort-indicator icons plus spacing of 10px on both sides
		padding-right: 10px + (26px * 2) + 10px;

		&.selected {
			.sort-indicators {
				color: var(--fm-sort-flyout-sort-indicator-selected-next-color);
			}

			&.asc .icon-sort-descending,
			&.desc .icon-sort-ascending {
				color: var(--fm-sort-flyout-sort-indicator-selected-current-color);
			}
		}

		&:not(.selected) {
			@include hover {
				.icon-sort-descending {
					color: var(--fm-sort-flyout-sort-indicator-hover-color);
				}

				.icon-sort-ascending {
					color: var(--fm-sort-flyout-sort-indicator-next-hover-color);
				}
			}
		}
	}
}
