$header-height: 58px;
$header-bottom-space: var(--gap-l);

$footer-height: 60px;
$footer-top-space: 30px;

$form-group-bottom-margin: 28px;

$content-side-margin: var(--gap-s);

$menubar-height: 95px;
$menubar-to-content-space: var(--gap-xs);

$header-footer-hor-padding: var(--gap-l);

:root {
	--gap-xs: 8px;
	--gap-s: 12px;
	--gap-m: 16px;
	--gap-l: 24px;
	--gap-xl: 32px;
	--gap-xxl: 48px;
}
