/* Open sans regular */
@use "sass:list";

@font-face {
	font-family: "Open sans";
	src: url('client/fonts/OpenSans-Regular-webfont.eot') format('embedded-opentype'),url('client/fonts/OpenSans-Regular-webfont.woff') format('woff'),url('client/fonts/OpenSans-Regular-webfont.woff2') format('woff2'),url('client/fonts/OpenSans-Regular-webfont.ttf') format('truetype'),url('client/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* Open sans semi-bold variant */
@font-face {
	font-family: "Open sans";
	src: url('client/fonts/OpenSans-SemiBold-webfont.eot') format('embedded-opentype'),url('client/fonts/OpenSans-SemiBold-webfont.woff') format('woff'),url('client/fonts/OpenSans-SemiBold-webfont.woff2') format('woff2'),url('client/fonts/OpenSans-SemiBold-webfont.ttf') format('truetype'),url('client/fonts/OpenSans-SemiBold-webfont.svg#open_sansregular') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

/* Open sans bold variant */
@font-face {
	font-family: "Open sans";
	src: url('client/fonts/OpenSans-Bold-webfont.eot') format('embedded-opentype'),url('client/fonts/OpenSans-Bold-webfont.woff') format('woff'),url('client/fonts/OpenSans-Bold-webfont.woff2') format('woff2'),url('client/fonts/OpenSans-Bold-webfont.ttf') format('truetype'),url('client/fonts/OpenSans-Bold-webfont.svg#open_sansregular') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/* Font variables */
$base-font-family: Open sans, Arial, Helvetica, sans-serif;
$base-font-size: 16px;
$small-font-size: 14px;

$h1-font: 600 40px/48px $base-font-family;
$h1-margin: 3px 0 10px 0;
$h2-font: 600 28px/33.6px $base-font-family;
$h3-font: 600 24px/28.8px $base-font-family;
$h4-font: 600 20px/24px $base-font-family;
$h5-font: 600 18px/24px $base-font-family;

$overlay-title-font: $h3-font;
$overlay-subject-font: $h5-font;
$breadcrumb-font-size: $base-font-size;
$sharelink-title-font-size: $small-font-size;
