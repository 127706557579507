
.icon-darthvader {
	background-image: url('client/icons/darthvader.svg');
	background-size: contain;
}

.icon-darthvader-off {
	background-image: url('client/icons/darthvader_off.svg');
	background-size: contain;
}

.icon-luke {
	background-image: url('client/icons/luke.svg');
	background-size: contain;
}

.icon-luke-off {
	background-image: url('client/icons/luke_off.svg');
	background-size: contain;
}

.icon-cookies {
	background-image: url('client/icons/cookies.svg');
	background-size: contain;
	width: 100px;
	height: 100px;
}
