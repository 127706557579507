$dropdownHeight: 44px;
$dropdownBorderWidth: 1px;

.sj-simple-dropdown {

	border: $dropdownBorderWidth solid var(--dropdown-border-color);
	border-radius: $editorBorderRadius;

	background-color: var(--dropdown-background-color);

	// required to position the elements inside
	position: relative;

	&:hover {
		border-color: var(--dropdown-hover-border-color);

		.arrow-icon {
			color: var(--dropdown-hover-arrow-color)
		}
	}

	&.sj-simple-dropdown-focused {
		border-color: var(--dropdown-focused-border-color);

		.arrow-icon {
			color: var(--dropdown-focused-arrow-color)
		}
	}

	&.sj-simple-dropdown-disabled {
		background-color: var(--dropdown-disabled-background-color);
		border-color: var(--dropdown-disabled-border-color);

		.arrow-icon {
			color: var(--dropdown-disabled-arrow-color)
		}

		select {
			color: var(--dropdown-disabled-text-color);
		}
	}

	select {
		// reset browsers default style
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		background-color: var(--dropdown-background-color);
		color: var(--dropdown-text-color);
		width: 100%;
		padding: 0 30px 0 10px;
		outline: none;
		border: none;
		border-radius: $editorBorderRadius;

		height: $dropdownHeight - $dropdownBorderWidth * 2;

		// required to be "higher" than the arrow-icon
		position: relative;

		// remove dotted border around current selected value in FireFox
		&:-moz-focusring {
			/* In context of an element with :-moz-focusring color and
			 * text-shadow have different meaning than usual. color applies to
			 * the (dotted) outline and text-shadow applies to the text color
			 * itself (as if text-shadow is the replacement for usual "color").
			 */
			color: transparent; // hide the focus outline
			text-shadow: 0 0 0 #000;

			/* Since the styles inherit from select to its options, reset the
			 * styling to the normal colors.
			 */
			* {
				color: var(--dropdown-text-color);
				text-shadow: none;
			}
		}
	}

	.arrow-icon {
		@extend .icon-dropdown;
		position: absolute;
		top: $dropdownHeight * 0.5 - 15px * 0.5;
		right: 10px;
		color: var(--dropdown-arrow-color);
		pointer-events: none;
	}
}
