overflow-menubar {
	visibility: hidden;
	min-width: 0;

	&.overflow-menubar-layout-done {
		visibility: initial;
	}
}


overflow-menubar-items {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	> * {
		flex: 0 0 auto;
	}

	> .sj-button,
	> .sj-menu-button > .sj-button {
		margin-right: 0;
	}

	> .sj-button,
	> .sj-menu-button {
		margin-left: var(--gap-s);
	}

	.overflow-menubar-item-hidden {
		display: none;
	}

	> menu-separator {
		display: none;
	}
}

