.folder-breadcrumb {
	a.link:not(.level-up) {
		cursor: pointer;
		@include hover {
			text-decoration: underline;
		}
		@include active {
			text-decoration: underline;
		}
	}
}

