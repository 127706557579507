$button-height: 44px;

// To define background/border styles for secondary buttons at one place only
@mixin secondary-colors-and-borders {
	background-color: var(--button-secondary-background-color);
	border: $buttonBorderWidth solid var(--button-secondary-border-color);
	color: var(--button-secondary-color);

	@include hover {
		color: var(--button-secondary-hover-color);
		background-color: var(--button-secondary-hover-background-color);
		border-color: var(--button-secondary-hover-border-color);
	}

	@include active {
		color: var(--button-secondary-active-color);
		background-color: var(--button-secondary-active-background-color);
		border-color: var(--button-secondary-active-border-color);
	}

	&.sj-button-disabled,
	&:disabled {
		color: var(--button-secondary-disabled-color);
		background-color: var(--button-secondary-disabled-background-color);
		border-color: var(--button-secondary-disabled-border-color);

		opacity: 0.25;
	}
}

@mixin alternative-colors-and-borders {
	background-color: var(--button-alternative-background-color);
	border: $buttonBorderWidth solid var(--button-alternative-border-color);
	color: var(--button-alternative-color);

	@include hover {
		color: var(--button-alternative-hover-color);
		background-color: var(--button-alternative-hover-background-color);
		border-color: var(--button-alternative-hover-border-color);
	}

	@include active {
		color: var(--button-alternative-active-color);
		background-color: var(--button-alternative-active-background-color);
		border-color: var(--button-alternative-active-border-color);
	}

	&.sj-button-disabled,
	&:disabled {
		color: var(--button-alternative-disabled-color);
		background-color: var(--button-alternative-disabled-background-color);
		border-color: var(--button-alternative-disabled-border-color);
	}
}

@mixin link-styled {
	background-color: transparent;
	border: $buttonBorderWidth solid transparent;
	color: var(--link-color);
	font-weight: normal;

	width: auto;

	margin: 0;
	line-height: 26px;
	height: auto;

	.label {
		display: inline-block;
		margin-right: 0;
	}

	> .icon {
		color: var(--link-icon-color);
		width: auto;
		margin-right: 5px;
		height: 26px;
		line-height: 26px;
		font-size: 16px;
		padding: 0;
	}

	@include hover {
		color: var(--link-hover-color);
		background-color: transparent;
		border-color: transparent;

		> .icon {
			color: var(--link-icon-hover-color);
		}
	}

	@include active {
		color: var(--link-active-color);
		background-color: transparent;
		border-color: transparent;

		> .icon {
			color: var(--link-icon-active-color);
		}
	}
}

.sj-button {

	// Force styles here: browsers will override inherited fonts - since
	// they're defining "font" (which is stronger) than "font-xyz" - when
	// applied to button/input elements
	font-family: $base-font-family;
	font-size: $base-font-size;
	font-weight: 600;

	box-sizing: border-box;
	border-radius: $buttonBorderRadius;
	border: $buttonBorderWidth solid var(--button-primary-border-color);

	position: relative;
	display: inline-block;
	overflow: hidden;
	height: $button-height;
	line-height: $button-height - 2 * $buttonBorderWidth;
	min-width: 100px;
	text-align: center;

	outline: none;

	margin-right: var(--gap-m);
	padding: 0;

	cursor: pointer;

	color: var(--button-primary-color);
	background-color: var(--button-primary-background-color);

	@include hover {
		background-color: var(--button-primary-hover-background-color);
		border-color: var(--button-primary-hover-border-color);
		color: var(--button-primary-hover-color);
	}

	@include active {
		background-color: var(--button-primary-active-background-color);
		border-color: var(--button-primary-active-border-color);
	}

	&.sj-button-disabled,
	&:disabled {
		cursor: default;
		pointer-events: none;

		color: var(--button-primary-disabled-color);
		background-color: var(--button-primary-disabled-background-color);
		border-color: var(--button-primary-disabled-border-color);

		opacity: var(--button-primary-disabled-opacity);
	}

	&.secondary {
		// define background/border colors
		@include secondary-colors-and-borders;

		&.sj-button-disabled,
		&:disabled {
			cursor: default;
		}
	}

	&.alternative {
		// define background/border colors
		@include alternative-colors-and-borders;

		&.sj-button-disabled,
		&:disabled {
			cursor: default;
		}
	}

	&.linkstyled {
		// define background/border colors
		@include link-styled;

		.label {
			display: inline-block;
			margin-right: 20px;
		}

		&.sj-button-disabled,
		&:disabled {
			cursor: default;
		}
	}

	.label {
		margin-right: 20px;
		margin-left: 20px;
	}
}

.sj-icon-button {
	min-width: 0;// Icon buttons should not have a min-width (override sj-button)

	width: $button-height;// to hide text for smaller device width, overridden in @desktop

	@include desktop {
		width: auto;
	}

	white-space: nowrap;

	.label {
		margin-left: 0;

		display: none;
		@include desktop {
			display: inline-block;
		}
	}

	&:not(.sj-icon-only-button) {
		> .icon {
			line-height: $button-height - 2 * $buttonBorderWidth;
			height: $button-height - 2 * $buttonBorderWidth;
			width: auto;
			text-align: center;
			position: relative;
			vertical-align: top;
			font-size: 16px;

			@include desktop {
				text-align: right;
				padding-left: 16px;
				padding-right: 8px;
			}
		}
	}
}

.sj-icon-button, .sj-button {
	.file-input-wrapper {
		input {
			display: none;
		}
	}
}

.sj-icon-only-button {
	width: $button-height;
	min-width: 0;
	height: $button-height;
	padding: 0;
	text-align: center;

	.icon {
		line-height: $button-height - 2 * $buttonBorderWidth;
	}

	.label {
		display: none;
	}

	// define background/border colors
	@include secondary-colors-and-borders;

	&.selected {
		cursor: default;

		.icon {
			color: var(--button-icon-only-selected-icon-color);
		}

		background-color: var(--button-icon-only-selected-background-color);
		border-color: var(--button-icon-only-selected-border-color);
	}
}
